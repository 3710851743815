import styled from 'styled-components';

import { Button, Layer } from '@betty-blocks/design';

const IMAGE_WIDTH = 42;
const IMAGE_HEIGHT = 26;
const IMAGE_PADDING = 5;
const BORDER_WIDTH = 0.0625;

export const ContactPersonWrapper = styled.div`
  text-align: center;

  & a {
    color: ${({ theme }): string => theme.global.colors.grey700};
  }
`;

export const ContactPersonName = styled.h3`
  font-size: 1.25rem;
  line-height: 1.75rem;
  color: ${({ theme }): string => theme.global.colors.grey900};
  margin-top: 0;
  margin-bottom: 0;

  & a {
    text-decoration: none;
    color: ${({ theme }): string => theme.global.colors.grey900};

    &:hover {
      text-decoration: underline;
    }
  }
`;

export const ContactPersonAvatar = styled.div`
  width: 6rem;
  height: 6rem;
  margin: 0 auto 1rem;
  border: ${({ theme }): string =>
    `0.0625rem solid ${theme.global.neutralGrey100}`};
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;

  & img {
    max-width: 100%;
  }
`;

export const ContactPersonText = styled.div`
  font-size: 1rem;
  line-height: 1.5rem;

  & a {
    color: ${({ theme }): string => theme.global.colors.grey700};
  }
`;

export const FormLabel = styled.div`
  font-size: 0.75rem;
  font-weight: bold;
  line-height: 1rem;
  text-transform: uppercase;
  color: ${({ theme }): string => theme.global.colors.grey700};
  margin-bottom: 0.25rem;
`;

export const FormHelper = styled.div`
  font-size: 0.75rem;
  line-height: 1rem;
  color: ${({ theme }): string => theme.global.colors.grey700};
`;

export const FormInput = styled.div`
  display: flex;

  & button {
    height: 2.5rem;
  }

  & div + button {
    white-space: nowrap;
    margin-left: 0.5rem;
  }
`;

export const ImageCarouselModalLayer = styled.div<{
  isOpen: boolean;
  level: number;
  total: number;
}>`
  position: ${({ isOpen }): string => (isOpen ? 'fixed' : 'static')};
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${({ isOpen }): string =>
    isOpen ? 'rgba(0, 0, 0, 0.75)' : 'transparent'};
  z-index: 1;

  & div.modal {
    position: ${({ isOpen }): string => (isOpen ? 'absolute' : 'static')};
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  & div.carousel-wrapper {
    position: relative;
    width: ${({ isOpen }): string => (isOpen ? '100%' : `${IMAGE_WIDTH}rem`)};
    height: ${({ isOpen }): string =>
      isOpen ? '100%' : `${IMAGE_HEIGHT + 2 * IMAGE_PADDING}rem`};
    padding: ${({ isOpen }): string =>
      isOpen ? '0' : `${IMAGE_PADDING}rem 0`};
    overflow: hidden;
  }

  & div.image-carousel-container {
    width: ${({ total, isOpen }): string =>
      isOpen ? `${total * 100}%` : `${total * IMAGE_WIDTH}rem`};
    height: ${({ isOpen }): string => (isOpen ? '100%' : `${IMAGE_HEIGHT}rem`)};
    transition: ${({ isOpen }): string =>
      isOpen ? 'none' : 'transform 300ms ease-in-out'};
    transform: ${({ level, isOpen, total }): string =>
      isOpen
        ? `translateX(-${(level / total) * 100}%)`
        : `translateX(-${level * IMAGE_WIDTH}rem)`};

    & .image-button {
      width: ${({ isOpen, total }): string =>
        isOpen ? `${100 / total}%` : 'auto'};
      height: ${({ isOpen }): string => (isOpen ? '100%' : 'auto')};
      background-color: transparent;
      border: ${({ theme, isOpen }): string =>
        isOpen
          ? 'none'
          : `${BORDER_WIDTH}rem solid ${theme.colors.neutralGrey100}`};
      margin: 0;
      padding: 0;
      font-size: 0;

      &:focus {
        outline: none;
      }
    }

    & img {
      width: ${({ isOpen }): string =>
        isOpen ? '100%' : `${IMAGE_WIDTH - 2 * BORDER_WIDTH}rem`};
      height: ${({ isOpen }): string =>
        isOpen ? '100%' : `${IMAGE_HEIGHT - 2 * BORDER_WIDTH}rem`};
      object-fit: ${({ isOpen }): string => (isOpen ? 'contain' : 'cover')};
      object-position: center;
      cursor: ${({ isOpen }): string => (isOpen ? 'default' : 'zoom-in')};
    }
  }

  & div.navigation {
    position: absolute;
    bottom: ${(): string => `${IMAGE_PADDING}rem`};
    width: ${({ isOpen }): string => (isOpen ? '100%' : `${IMAGE_WIDTH}rem`)};
    height: 2rem;
  }
`;

export const ImageCarouselModalButton = styled(Button)`
  position: absolute;
  z-index: 1;
  top: 1rem;
  right: 1rem;
  border: none;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 0.25rem;
  background-color: rgba(0, 0, 0, 0.75);
  cursor: pointer;

  &:hover,
  &:focus {
    border: none;
    background-color: rgba(0, 0, 0, 0.75);
  }
`;

export const ImageCarouselNavigationBars = styled.div`
  position: absolute;
  bottom: 0.5625rem;
  left: 50%;
  height: 2rem;
  transform: translateX(-50%);
`;

export const ImageCarouselNavigationBarsButton = styled.button<{
  active: boolean;
}>`
  font-size: 0;
  border: none;
  position: relative;
  display: inline-block;
  width: 2rem;
  height: 0.25rem;
  margin: 0 0.25rem;
  background-color: ${({ theme, active }): string =>
    active ? theme.global.colors.turquoise700 : theme.global.colors.grey100};
  transition: background-color 0.5s ease-in-out;
  cursor: pointer;

  &:hover,
  &:focus {
    background-color: ${({ theme }): string =>
      theme.global.colors.turquoise700};
  }
`;

export const ImageCarouselNavigationButtons = styled.div`
  position: absolute;
  bottom: 1.5rem;
  right: 1.5rem;
  height: 2.5rem;
`;

export const ImageCarouselNavigationButton = styled.button`
  font-size: 0;
  border: none;
  position: relative;
  display: inline-block;
  width: 2.5rem;
  height: 2.5rem;
  margin: 0 0.25rem;
  border-radius: 0.25rem;
  background-color: ${({ theme }): string => theme.global.colors.turquoise700};
  transition: background-color 0.5s ease-in-out;
  cursor: pointer;

  &:hover,
  &:focus {
    border: none;
    background-color: ${({ theme }): string =>
      theme.global.colors.turquoise900};
  }

  & svg {
    fill: ${({ theme }): string => theme.global.colors.white};
  }
`;

export const VideoLayer = styled(Layer)`
  width: 53.375rem;
  min-height: 30rem;
  box-shadow: 0 0.1rem 3rem rgba(0, 0, 0, 0.3);
  margin-top: 2rem;
`;

export const VideoContainer = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;
  margin: 0;

  & > iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border: none;
  }
`;

export const VideoCloseButton = styled(Button)`
  position: absolute;
  top: 1rem;
  right: 1rem;
  z-index: 100;
  border: none;
  background: none;
  &:hover {
    border: none;
    background: none;
    & > svg {
      fill: ${({ theme }): string => theme?.global?.colors.white};
      stroke: ${({ theme }): string => theme?.global?.colors.white};
    }
  }
`;
