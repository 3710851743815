import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { goToLogin, mutations } from '@my-bb/common';
import { errorUtils } from '@my-bb/common-utils';
import { toastQueueContext } from '@my-bb/common-components';
import { MainPage } from '../../components/MainPage';
import {
  BiggerFormField,
  BiggerInput,
  ConfirmButton,
  Title,
  SubTitle,
} from '../../components/MainPage/styled';

export function PasswordForgotten() {
  const { t } = useTranslation('main');

  const { addToToastQueue } = useContext(toastQueueContext);
  const [linkSent, setLinkSent] = useState(false);
  const [email, setEmail] = useState('');
  const [error, setError] = useState({});

  const [sendResetPasswordEmail, { loading }] = useMutation(
    mutations.sendResetPasswordEmail,
    {
      variables: {
        email,
      },
      onCompleted: (completeData) => {
        const mutError = errorUtils.extractError(
          completeData.sendResetPasswordEmail,
        );

        if (!mutError.type) {
          setLinkSent(true);
          return;
        }

        if (Object.keys(mutError.fields).length) {
          setError({
            ...error,
            ...mutError.fields,
          });
          return;
        }

        addToToastQueue(errorUtils.toastErrorData(mutError));
      },
    },
  );

  const isSubmitBtnActive = !error.email && email !== '';
  const handleSubmit = (evt) => {
    evt.preventDefault();
    if (!isSubmitBtnActive || loading) return;
    sendResetPasswordEmail();
  };

  const handleChange = (evt) => {
    const { value } = evt.target;
    setEmail(value);
    setError({});
  };

  const navigateToLogin = () => {
    goToLogin();
  };

  if (linkSent) {
    return (
      <MainPage>
        <Title>{t('PasswordForgotten.confirmation.title')}</Title>
        <SubTitle color="grey700">
          {t('PasswordForgotten.confirmation.subtitle')}
        </SubTitle>
        <div>
          <ConfirmButton
            fill
            primary
            color="turquoise"
            size="large"
            onClick={navigateToLogin}
          >
            {t('PasswordForgotten.confirmation.complete')}
          </ConfirmButton>
        </div>
      </MainPage>
    );
  }

  return (
    <MainPage>
      <Title>{t('PasswordForgotten.request.title')}</Title>
      <SubTitle color="grey700">
        {t('PasswordForgotten.request.subtitle')}
      </SubTitle>
      <form onSubmit={handleSubmit}>
        <BiggerFormField label={t('PasswordForgotten.request.labels.email')}>
          <BiggerInput
            size="large"
            name="email"
            value={email}
            error={error.email}
            onChange={handleChange}
          />
        </BiggerFormField>
        <ConfirmButton
          type="submit"
          fill
          primary
          color="turquoise"
          disabled={!isSubmitBtnActive}
          size="large"
          margin={{ top: '2rem' }}
          onClick={handleSubmit}
        >
          {t('PasswordForgotten.request.complete')}
        </ConfirmButton>
      </form>
    </MainPage>
  );
}
