import React, { useEffect, useState, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { mutations } from '@my-bb/common';
import { useTranslation } from 'react-i18next';
import { FullPageLoader, toastQueueContext } from '@my-bb/common-components';
import { OnboardingPage } from '../../components/OnboardingPage';
import { Title, SubTitle } from '../../components/MainPage/styled';
import { Form, ErrorBlock } from '../../components/SetPassword';
import onboardingRegisterBackground from '../../assets/graphics/onboardingRegisterBackground.svg';

export const SetPassword = () => {
  const { t } = useTranslation('main');
  const { addToToastQueue } = useContext(toastQueueContext);
  const [mailSend, setMailSend] = useState(false);
  const navigate = useNavigate();
  const { token } = useParams();
  const [validToken, setValidToken] = useState({ valid: false, reason: '' });

  const [resendEmail, { loading: resendLoading }] = useMutation(
    mutations.resendEmail,
    {
      variables: {
        input: {
          token,
        },
      },
      onCompleted: () => {
        setMailSend(true);
      },
      onError: () => {
        addToToastQueue({
          type: 'failed',
          body: t('General.somethingWrong'),
        });
      },
    },
  );

  const [validateToken, { loading }] = useMutation(mutations.validateToken, {
    variables: {
      input: {
        token,
      },
    },
    onCompleted: (completeData) => {
      const response = completeData.validateToken;
      if (response.__typename.includes('Ok')) {
        setValidToken(() => {
          return {
            valid: true,
            reason: '',
          };
        });
        return;
      }

      setValidToken(() => {
        return {
          valid: false,
          reason: response.inputErrors[0].type,
        };
      });
    },
  });

  useEffect(() => {
    validateToken();
  }, []);

  const renderErrorBlock = () => {
    if (validToken.reason === 'EXPIRED') {
      return (
        <ErrorBlock
          title={t('SetPassword.expiredToken.title')}
          subTitle={t('SetPassword.expiredToken.subTitle')}
          buttonLabel={t('SetPassword.expiredToken.buttonLabel')}
          buttonClickHandler={() => resendEmail()}
          loading={resendLoading}
        />
      );
    }

    return (
      <ErrorBlock
        title={t('SetPassword.invalidToken.title')}
        subTitle={t('SetPassword.invalidToken.subTitle')}
        buttonLabel={t('SetPassword.invalidToken.buttonLabel')}
        buttonClickHandler={() => navigate('/register')}
        loading={false}
      />
    );
  };

  const renderContent = () => {
    if (loading) {
      return <FullPageLoader />;
    }

    if (mailSend) {
      return (
        <OnboardingPage backgroundImage={onboardingRegisterBackground}>
          <Title>{t('PublicRegister.succesTitle')}</Title>
          <SubTitle>{t('PublicRegister.succesSubtitleNoEmail')}</SubTitle>
        </OnboardingPage>
      );
    }

    return (
      <OnboardingPage backgroundImage={onboardingRegisterBackground}>
        {!validToken.valid ? renderErrorBlock() : <Form />}
      </OnboardingPage>
    );
  };

  return renderContent();
};
