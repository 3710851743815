import { routes } from '@my-bb/common';

export const General = {
  somethingWrong: 'Oeps, er is iets misgegaan!',
  delete: 'Verwijder',
  cancel: 'Annuleren',
  save: 'Wijzigingen opslaan',
  sortupdated_at: 'Nieuwste eerst',
  sortname: 'A-Z',
};

export const ErrorPage = {
  button: 'Terug naar het dashboard',
  note: 'Of creëer zelf een app!',
  footer: 'Wat is Betty Blocks?',
};

export const failurePage = {
  expected: 'Deze applicatie reageert niet als we verwachten',
  failure: 'Uhm… Er is iets verkeerd gegaan',
};

export const ForbiddenPage = {
  access: 'Je hebt geen toegang tot deze applicatie',
  failure: 'Uhm… Er is iets verkeerd gegaan',
};

export const NotFoundPage = {
  notFound: 'Deze applicatie kan niet gevonden worden',
  failure: 'Uhm… Er is iets verkeerd gegaan',
};

export const MainPage = {
  footer: 'Copyright © Betty Blocks {{year}}. Alle rechten voorbehouden',
};

export const ErrorBoundary = {
  title: 'Oeps, er is iets mis gegaan!',
  subtitle:
    'Vertel ons wat je probeerde te doen, en wij proberen het voor je te repareren!',
  callToAction: 'Geef feedback',
  imageAlt: 'Gevallen ijsje met een gezicht',
};

export const GalleryTags = {
  lead_generation: 'Lead generation',
  dynamic_questionnaire: 'Dynamic questionnaire',
  quick_scan: 'Quick scan',
  compliance_tracking: 'Compliance tracking',
  client_intake: 'Client intake',
  client_portal: 'Client portal',
  portal: 'Portal',
  document_automation: 'Document automation',
  knowledge_center: 'Knowledge center',
  intranet: 'Intranet',
  ideation_portal: 'Ideation portal',
  information_portal: 'Information portal',
  workflow: 'Workflow',
  ai: 'AI',
  generative_ai: 'Generative AI',
  chatbot: 'Chatbot',
  risk_assessment: 'Risk assessment',
  employee_portal: 'Employee portal',
  checklist: 'Checklist',
  questionnaire: `Questionnaire`,
  project_management: `Project management`,
  dashboard: `Dashboard`,
  integrations: `Integrations`,
};

export const Sidebar = {
  logout: 'Uitloggen',
  profile: 'Accountinstellingen',
  account: 'Account',
  dashboard: 'Dashboard',
  organization: 'Organisaties',
  application: 'Applicaties',
  blockStore: 'Block Store',
  communty: 'Community',
  documentation: 'Documentatie',
  productChanges: 'Product changes',
  status: 'Status',
  galleries: 'Galleries',
  customer: 'Klant',
};

export const Dashboard = {
  community: 'Community',
  learning: 'Leerpaden',
  documentation: 'Documentatie',
  productChanges: 'Productwijzigingen',
  discover: 'Introductietour',
  communityInfo: 'Deel je vragen of ideeën met de community.',
  learningInfo:
    'Volg onze tutorials over hoe je met de functionaliteiten van ons platform kan bouwen.',
  documentationInfo:
    'Vind antwoorden en tips voor het oplossen van jouw uitdagingen tijdens het bouwen van je app.',
  productChangesInfo:
    'Bekijk wat er nieuw is in Betty Blocks en wat we tot nu toe hebben uitgebracht.',
  hi: 'Hi',
  welcome: 'Welkom op het My Betty Blocks dashboard.',
  learn: 'Leer Betty Blocks',
  discover_info:
    'Ontdek My Betty Blocks en de functionaliteiten die voor jou beschikbaar zijn voor het maken, beheren en verrijken van jouw applicaties.',
  learnSubtitle: 'Er zijn verschillende manieren om je op weg te helpen.',
  primaryVideoTitle: 'Begin met onze introductie video',
  primaryVideoSubTitle:
    'Leer de Betty Blocks fundamentals binnen slechts 5 minuten.',
  secondaryVideoTitle: 'Over Betty Blocks en citizen development',
  secondaryVideoSubTitle:
    'Ontdek de strategie achter Betty Blocks en citizen development.',
  startBuilding: 'Begin met bouwen',
  startBuildingSubTitle:
    'Doe ervaring op met Betty Blocks door een guided building tour te volgen of start met bouwen aan een nieuwe applicatie.',
  buttonAllTemplates: 'Alle templates tonen',
  start: 'Start de tour',
};

export const PublicRegister = {
  title: 'Registeren',
  subtitle: 'Begin met Betty Blocks door eerst een account aan te maken',
  succesTitle: 'Je hebt een mailtje!',
  succesSubtitle:
    'We hebben een email gestuurd naar {{email}}. Klik op de link in de email om de registratie te voltooien.',
  succesSubtitleNoEmail:
    'We hebben een email gestuurd. Klik op de link in de email om de registratie te voltooien.',
  labels: {
    firstName: 'Voornaam *',
    lastName: 'Achternaam *',
    email: 'Zakelijke email *',
    emailPlaceholder:
      'Een geldig email adres is verplicht om je account te verifiëren',
    phone: 'Telefoonnummer *',
    companySize: 'Bedrijfsgrootte *',
    experience: 'Ervaringsniveau *',
    newsletterConsent:
      'Ik ga akkoord om de Betty Blocks experience updates en informatie te ontvangen *',
  },
  errors: {
    email: {
      FIELD_NOT_ALLOWED: 'Alleen zakelijke emailadressen zijn toegestaan',
      ALREADY_TAKEN: 'Er bestaat al een account met dit emailadres',
    },
    captcha: {
      INVALID:
        'Er is iets mis gegaan. Neem contact met ons via support@bettyblocks.com op als je denkt dat het probleem bij ons ligt (Captcha error)',
    },
    required: {
      firstName: 'Voornaam is verplicht',
      lastName: 'Achternaam is verplicht',
      email: 'Zakelijke email is verplicht',
      phone: 'Telefoonnummer is verplicht',
      companySize: 'Bedrijfsgrootte is verplicht',
      experience: 'Ervaringsniveau is verplicht',
      agreementTerms: 'Het is verplicht akkoord te gaan om te registeren',
    },
    format: {
      email: 'Ongeldig mailadres',
      phone:
        'Alleen cijfers, spaties en + - () tekens zijn toegestaan. Minimaal 5 karakters.',
    },
  },
  hasAccount: 'Heb je al een account?',
  login: 'Inloggen',
  companySize: {
    default: 'Selecteer bedrijfsgrootte',
    xxsmall: '1 tot 50',
    xsmall: '51 tot 200',
    small: '201 tot 500',
    medium: '501 tot 1000',
    large: '1001 tot 5000',
    xlarge: '5001 tot 10000',
    xxlarge: '10000+',
  },
  experience: {
    select: 'Heb je ontwikkelervaring?',
    noCoder: 'No-code / Low-code ervaring',
    dev: 'Development ervaring',
    none: 'Geen ervaring',
  },
  agreementInfo:
    'Om ervoor te zorgen dat je de beste en meest complete Betty Blocks-ervaring krijgt, willen we je aanvullende inhoud en materialen sturen. We zullen jouw adres niet voor andere doeleinden gebruiken.',
  completeInfo:
    '* Door jezelf te registreren geef je Betty Blocks toestemming om de opgegeven accountinformatie op te slaan.',
  complete: 'Registreer',
  agreementLabel: `Ik ga akkoord met Betty Blocks'`,
  termsAndConditions: 'voorwaarden',
  agreementAnd: 'en het',
  privacyPolicy: 'privacybeleid',
};

export const InviteRegister = {
  title: 'Registreren',
  subTitle: 'Begin met Betty Blocks door eerst een account aan te maken',
  labels: {
    firstName: 'Voornaam *',
    lastName: 'Achternaam *',
    experience: 'Ervaringsniveau *',
    intention: 'Wat brengt je naar Betty Blocks? *',
    password: 'Wachtwoord *',
    confirmPassword: 'Bevestig wachtwoord *',
    newsletterConsent:
      'Ik ga akkoord om de Betty Blocks experience updates en informatie te ontvangen',
    agreementLabel: `Ik ga akkoord met Betty Blocks'`,
    termsAndConditions: 'voorwaarden',
    agreementAnd: 'en het',
    privacyPolicy: 'privacybeleid',
  },
  errors: {
    registration: 'Je hebt al geregisteerd.',
    captcha: {
      INVALID:
        'Er is iets mis gegaan. Neem contact met ons via support@bettyblocks.com op als je denkt dat het probleem bij ons ligt (Captcha error)',
    },
    required: {
      firstName: 'Voornaam is verplicht',
      lastName: 'Achternaam is verplicht',
      experience: 'Ervaringsniveau is verplicht',
      intention: 'We willen graag weten waarom je het platform gaat gebruiken',
      password: 'Wachtwoord is verplicht',
      confirmPassword: 'Bevestig wachtwoord is verplicht',
      agreementTerms: 'Het is verplicht akkoord te gaan om te registeren',
    },
  },
  hasAccount: 'Heb je al een account?',
  login: 'Inloggen',
  experience: {
    select: 'Heb je ontwikkelervaring?',
    noCoder: 'No-code / Low-code ervaring',
    dev: 'Development ervaring',
    none: 'Geen ervaring',
  },
  intention: {
    select: 'Wat brengt je naar Betty Blocks?',
    try: 'Ik ga het platform uitproberen',
    together: 'Ik ga samen met iemand een applicatie bouwen',
    alone: 'Ik ga zelf bouwen',
    trained: 'Ik word getraind om het platform te gebruiken',
  },
  agreementInfo:
    'Om ervoor te zorgen dat je de beste en meest complete Betty Blocks-ervaring krijgt, willen we je aanvullende inhoud en materialen sturen. We zullen jouw adres niet voor andere doeleinden gebruiken.',
  completeInfo:
    '* Door jezelf te registreren geef je Betty Blocks toestemming om de opgegeven accountinformatie op te slaan.',
  complete: 'Registratie afronden',
};

export const Register = {
  title: 'Registreren',
  subtitle: 'Begin door eerst een account aan te maken',
  labels: {
    name: 'Volledige name',
    password: 'Wachtwoord',
  },
  complete: 'Registreer',
};

export const SetPassword = {
  invalidToken: {
    title: 'Email onbekend',
    subTitle:
      'Deze email is onbekend, keer terug naar de registratiepagina om een account aan te maken.',
    buttonLabel: 'Keer terug om te registreren',
  },
  expiredToken: {
    title: 'Deze link is verlopen',
    subTitle:
      'Klik op opnieuw verzenden zodat we je een nieuwe link kunnen sturen.',
    buttonLabel: 'Opnieuw verzenden',
  },
  title: 'Wachtwoord instellen',
  subtitle: 'Rond de registratie af door een wachtwoord in te stellen.',
  labels: {
    password: 'Wachtwoord',
    confirmPassword: 'Bevestig wachtwoord',
  },
  complete: 'Registratie afronden',
};

export const SettingsTabs = {
  profile: 'Profiel',
  password: 'Wachtwoord',
  title: 'Accountinstellingen',
  back: 'Terug',
};

export const ProfileSettings = {
  desc: 'Instellingen om je naam, e-mailadres en profielfoto te wijzigen',
  name: 'Naam',
  email: 'E-mailadres',
  uploadTitle: 'Profielfoto',
  uploadBtnText: 'Nieuwe foto uploaden',
  successMsg: 'Profiel instellingen bijgewerkt.',
};

export const PasswordSettings = {
  title: 'Verander je wachtwoord',
  password: 'Huidig ​​wachtwoord',
  newPassword: 'Nieuw ​​wachtwoord',
  confirmPassword: 'Bevestig wachtwoord',
  successMsg: 'Wachtwoord bijgewerkt.',
  twoFactor: {
    title: 'Two factor authenticatie',
    desc: 'Beveilig je account door two factor authenticatie te activeren.',
    label: 'Activeer two factor authenticatie',
  },
};

export const TwoFactorModal = {
  title: 'Two factor authenticatie instellen',
  title_disabled: 'Deactiveer two factor authenticatie',
  desc: 'Scan de QR code of vul de code handmatig in met een authenticatie app op je mobiel.',
  label: 'Bevestigings code',
  helperText:
    'Er zal een code van 6 cijfers worden getoond in de authenticatie app na het scannen van de QR code. Vul deze code in om de two factor authenticatie te activeren.',
  helperText_disabled:
    'Vul de code van 6 nummers, verkregen door de authenticatie app, in om de two factor authenticatie te deactiveren.',
  inputHelperText:
    'Verwijder de code uit je authenticatie app na het deactiveren van de two factor authenticatie.',
  confirm: 'Activeer',
  disabled: 'Deactiveer',
  dismiss: 'Annuleren',
  successMsg: 'Two factor authenticatie succesvol geactiveerd',
  successMsg_disabled: 'Two factor authenticatie succesvol gedeactiveerd',
  successMsg:
    'Activeren two factor authenticatie is mislukt, herlaad de pagina en probeer opnieuw',
  successMsg_disabled:
    'Deactiveren two factor authenticatie is mislukt, herlaad de pagina en probeer opnieuw',
};

export const PasswordForgotten = {
  request: {
    title: 'Wachtwoord vergeten',
    subtitle: 'We sturen je een link om je wachtwoord te wijzigen.',
    labels: {
      email: 'E-mail',
    },
    complete: 'Wijzig wachtwoord',
  },
  confirmation: {
    title: 'E-mail verzonden',
    subtitle:
      'We hebben een e-mail verstuurd met daarin een link om je wachtwoord te wijzigen.',
    complete: 'Terug naar login',
  },
};

export const ResetPassword = {
  request: {
    title: 'Vul een nieuw wachtwoord in',
    subtitle: 'Vul een nieuw wachtwoord in en klik op wijzig wachtwoord.',
    labels: {
      password: 'Wachtwoord',
    },
    complete: 'Wijzig wachtwoord',
    info: 'informatie',
  },
  confirmation: {
    title: 'Gelukt!',
    subtitle: 'Je wachtwoord is succesvol gewijzigd.',
    complete: 'Terug naar login',
  },
};

export const Routes = {
  [routes.ORGANIZATIONS]: 'Organisaties',
  [routes.ORGANIZATIONS + routes.ORGANIZATION_DETAILS]: '{{organization}}',
  [routes.ORGANIZATION_SETTINGS]: 'Instellingen van {{organization}}',
  [routes.ORGANIZATION_USERS]: 'Gebruikersbeheer',
  [routes.ORGANIZATION_ROLES]: 'Rollen & permissies',
  [routes.APPLICATIONS]: 'Applicaties',
  [routes.APPLICATIONS + routes.APPLICATION_DETAIL]: '{{mainApplication}}',
  [routes.MERGE_PLAN]: 'Merge overzicht',
  [routes.REBASE_PLAN]: 'Updates van {{application}}',
  [routes.ROLLBACK_PLAN]: 'Rollback overzicht',
  [routes.APPLICATION_SETTINGS]: 'Instellingen van {{application}}',
  [routes.MAIN_APPLICATION_SETTINGS]: 'Instellingen van {{application}}',
  [routes.SANDBOX_APPLICATION_SETTINGS]: 'Instellingen van {{application}}',
  [routes.APPLICATIONS + routes.USER_MANAGEMENT]:
    'Gebruikers van {{application}}',
  [routes.BLOCK_STORE]: 'Block Store',
  [routes.BLOCK_DETAIL]: '{{name}}',
  [`${routes.BLOCK_DETAIL}_dev`]: '{{name}} Dev',
  [routes.GALLERY]: 'App Gallery',
  [routes.GALLERY + routes.GALLERY_DETAIL]: '{{galleryItem}}',
  [routes.CUSTOMER]: 'Klant',
  [routes.CUSTOMER + routes.CUSTOMER_SETTINGS]: 'Instellingen',
  [routes.MERGE_HISTORY]: 'Merge geschiedenis van {{application}}',
};

export const OnboardingContent = {
  title: 'Beleef de Betty Blocks ervaring',
  text: 'Word lid van het Betty Blocks platform en ervaar de kracht van no-code in slechts een paar minuten. Volg een rondleiding, bekijk de kernelementen van het platform en ontdek hoe je het voor jou en jouw organisatie kunt inzetten.',
  subTitle: `We behandelen:`,
  listItemIntroduction: `Een introductie tot Betty Blocks' no-code platform`,
  listItemStepByStep: 'Stapsgewijs bouwen',
  listItemMainFeatures: 'De basisfeatures om no-code applicaties te bouwen',
  listItemLearningOpportunities:
    'Leermogelijkheden om voortduren je vaardigheden te verbeteren',
};

export const InvalidAuthModal = {
  confirm: 'Log opnieuw in',
  title: 'Je bent niet inlogd',
  content: 'Je sessie is verlopen door inactiviteit.',
};

export const Gallery = {
  paginationSubject: 'item',
  paginationSubject_plural: 'items',
  viewPreview: 'Toon live demo',
  videoTitle: 'Bekijk een presentatie',
  videoDescription: 'Binnen 5 minuten een uitgebreide uitleg',
  modalClose: 'Sluiten',
  showDetails: 'Bekijk details',
  keyFeatures: 'Belangrijkste kenmerken',
  builtTheApp: 'Bouwde de applicatie',
  contactPerson: 'Contactpersoon',
};

export const GalleryInterest = {
  title: 'Geïnteresserd in deze app?',
  body: 'Ook geïnteresseerd om het volledige potentieel van deze applicatie te benutten? Til je app naar een hoger niveau met onze krachtige oplossing. Maak uw interesse kenbaar, en wij zorgen ervoor dat u er mee aan de slag gaat!',
  actionText: 'Meer info',
  footer: 'We nemen zo snel mogelijk contact met je op.',
};

export const GalleryInterestModal = {
  title: 'Geïnteresserd in deze app?',
  titleSent: 'Bedankt voor het tonen van uw interesse',
  body: {
    main: `Ook geïnteresseerd om het volledige potentieel van deze applicatie te benutten? Til je app naar een hoger niveau. Maak uw interesse kenbaar, en wij zorgen ervoor dat u er mee aan de slag gaat!`,
    extendedEmail: `Laat je e-mailadres achter en we nemen zo snel mogelijk contact met je op.`,
    sent: `We stellen uw vraag op prijs en willen u verzekeren dat we uw informatie hebben ontvangen. We nemen zo snel mogelijk contact met u op met meer informatie en hulp.
  Bedankt voor uw tijd en we kijken ernaar uit om snel contact met u op te nemen.`,
  },
  moreInfo: {
    label: 'Meer info over',
    gettingStarted: 'Op weg helpen',
    implementation: 'Implementatie',
    pricing: 'Kosten',
    other: 'Anders',
  },
  emailLabel: 'Uw e-mail adres',
  helperEmail:
    'Geef uw e-mailadres op om op de hoogte te blijven (vrijblijvend en gratis).',
  formSubmit: 'Verzend verzoek',
};
