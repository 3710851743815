import React, { useState, ChangeEvent, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Text, TextInput } from '@betty-blocks/design';

import {
  useMixpanel,
  Modal,
  RadioButtonGroup,
  useKeyDown,
  MeContext,
} from '@my-bb/common-components';
import { string } from '@my-bb/common-utils';

import { FormHelper, FormInput, FormLabel } from './styled';
import { useLoggedIn } from '../../containers/GalleryDetection/GalleryDetection';

interface Props {
  closeModal: () => void;
  galleryCustomerId?: number | null;
  galleryItemId: number;
  galleryItemName: string;
  galleryItemSlug: string;
}

type HubspotInWindow = Window & {
  _hsq: unknown[];
};

export function ShowInterestedModal({
  closeModal,
  galleryCustomerId,
  galleryItemId,
  galleryItemName,
  galleryItemSlug,
}: Props): JSX.Element {
  const { t } = useTranslation('main', { keyPrefix: 'GalleryInterestModal' });
  const { trackEvent } = useMixpanel();
  const { user } = useContext(MeContext);
  const { isLoggedIn } = useLoggedIn();
  const [email, setEmail] = useState<string>(user?.email || '');
  const [disabled, setDisabled] = useState(!isLoggedIn);
  const [moreInfo, setMoreInfo] = useState('getting_started');
  const [isSent, setIsSent] = useState(false);

  const hsq = (window as unknown as HubspotInWindow)?._hsq || [];

  const moreInfoRadioOptions: { label: string; value: string }[] = [
    {
      label: t('moreInfo.gettingStarted'),
      value: 'getting_started',
    },
    {
      label: t('moreInfo.implementation'),
      value: 'implementation',
    },
    {
      label: t('moreInfo.pricing'),
      value: 'pricing',
    },
    {
      label: t('moreInfo.other'),
      value: 'other',
    },
  ];

  useEffect(() => {
    if (trackEvent) {
      trackEvent('Open interest in gallery modal', {
        'Customer Id': galleryCustomerId,
        Email: email,
        Id: galleryItemId,
        Name: galleryItemName,
        Slug: galleryItemSlug,
      });
    }
  }, []);

  const validateEmail = (input: string): boolean => {
    return string.isEmail(input);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const {
      target: { value },
    } = e;
    setEmail(value);
    const isValid = validateEmail(value);
    setDisabled(!isValid);
  };

  const handleMoreInfoChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;
    setMoreInfo(value);
  };

  const handleSubmit = (): void => {
    if (trackEvent) {
      trackEvent('Show interest in gallery', {
        'Customer Id': galleryCustomerId,
        Email: email,
        Id: galleryItemId,
        Name: galleryItemName,
        Slug: galleryItemSlug,
        MoreInfo: moreInfo,
      });
    }
    if (hsq) {
      hsq.push([
        'trackCustomBehavioralEvent',
        {
          name: 'pe538005_app_gallery___show_interest_v2',
          properties: {
            customerId: galleryCustomerId,
            galleryname: galleryItemName,
            email,
            slug: galleryItemSlug,
            moreInfo,
            id: galleryItemId,
          },
        },
      ]);
    }
    setIsSent(true);
    setEmail('');
  };

  useKeyDown(['Enter'], () => {
    const isValid = validateEmail(email);
    if (isValid && !isSent) {
      handleSubmit();
    }
  });

  return (
    <Modal
      title={isSent ? t('titleSent') : t('title')}
      onClose={closeModal}
      width="41.5rem"
      height="auto"
      padding="0rem 2rem"
    >
      <Box margin="0 0 2rem 0">
        {isSent ? (
          <Box>
            <Box pad="0">
              <Text
                as="p"
                margin={{ top: '0.5rem', bottom: '0' }}
                color="grey700"
              >
                {t('body.sent')}
              </Text>
            </Box>
          </Box>
        ) : (
          <>
            <Box>
              <Box pad="0 0 1.5rem">
                <Text
                  as="p"
                  margin={{ top: '0.5rem', bottom: '0.5rem' }}
                  color="grey700"
                >
                  {t('body.main')}
                </Text>
                <Text as="p" margin={{ bottom: '0rem' }} color="grey700">
                  {t('body.extendedEmail')}
                </Text>
              </Box>
            </Box>
            <Box fill="horizontal">
              <Box margin="0 0 1.5rem">
                <FormLabel>{t('moreInfo.label')}</FormLabel>
                <RadioButtonGroup
                  name={t('moreInfo.label')}
                  options={moreInfoRadioOptions}
                  value={moreInfo}
                  onChange={handleMoreInfoChange}
                />
              </Box>
              <FormLabel>{t('emailLabel')}</FormLabel>

              <FormInput>
                <TextInput
                  onChange={handleChange}
                  autoFocus
                  size="large"
                  value={email}
                />
                <Button
                  primary
                  label={t('formSubmit')}
                  size="large"
                  disabled={disabled}
                  onClick={handleSubmit}
                />
              </FormInput>
              <FormHelper>{t('helperEmail')}</FormHelper>
            </Box>
          </>
        )}
      </Box>
    </Modal>
  );
}
