import React from 'react';
import {
  Documentation,
  Forum,
  LearnIcon,
  ProductChanges,
} from '@betty-blocks/design';
import PropTypes from 'prop-types';

const ICON_REGISTRY = {
  documentation: Documentation,
  learning: LearnIcon,
  community: Forum,
  productChanges: ProductChanges,
};

export function Icon({ type, size, color }) {
  const IconComponent = ICON_REGISTRY[type];
  return <IconComponent color={color} size={size} />;
}

Icon.propTypes = {
  type: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
};
