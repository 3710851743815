import React, { MouseEvent } from 'react';
import { useNavigate, generatePath, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  CheckmarkIcon,
  OfficeBuildingIcon,
  UsersIcon,
} from '@betty-blocks/core-icons';
import { Button } from '@betty-blocks/design';

import { useAnonymousMixpanel } from '@my-bb/common-components';
import { routes } from '@my-bb/common';

import placeholderImage from '../../assets/graphics/gallery-placeholder.svg';
import galleryItemBackground from '../../assets/graphics/gallery-item-background.png';
import type { GallerListItem } from './types';
import {
  CardWrapper,
  CardImageWrapper,
  CardImage,
  CardContent,
  CardTitle,
  CardIndustryAndDepartment,
  CardIndustry,
  CardDepartment,
  CardDescription,
  CardFeatures,
  CardFeature,
  CardButtons,
  GalleryDetailTags,
  GalleryDetailTagsWrapper,
} from './styled';

interface Props {
  item: GallerListItem;
}

function Card({ item }: Props): JSX.Element {
  const { t } = useTranslation('main');
  const navigate = useNavigate();
  const { slug: gallerySlug = '' } = useParams();
  const { trackEvent } = useAnonymousMixpanel();

  const {
    cardColor,
    department,
    description,
    features,
    industry,
    image,
    id,
    name,
    slug,
    tags,
    urlToApp,
  } = item;

  const onCardclick = (): void => {
    if (trackEvent) {
      trackEvent('Gallery card clicked', {
        Name: name,
        Id: id,
        Slug: slug,
        Gallery: gallerySlug,
      });
    }
    navigate(
      generatePath(routes.GALLERY_DETAIL, {
        slug: gallerySlug,
        galleryItemSlug: slug,
      }),
    );
  };

  const onPreviewClick = (e: MouseEvent): void => {
    e.stopPropagation();
    trackEvent('Gallery card preview clicked', {
      Name: name,
      Id: id,
      Slug: slug,
      Gallery: gallerySlug,
    });
    if (urlToApp) {
      window.open(urlToApp, '_blank');
    }
  };

  return (
    <CardWrapper isLoading={false} disabled={false} onClick={onCardclick}>
      <CardImageWrapper
        backgroundImage={galleryItemBackground}
        color={cardColor || 'rgba(0, 0, 0, 1)'}
      >
        <CardImage backgroundImage={image || placeholderImage} />
        <CardButtons>
          <Button primary size="large" label={t('Gallery.showDetails')} />
          {urlToApp && (
            <Button
              secondary
              size="large"
              label="Preview"
              onClick={onPreviewClick}
            />
          )}
        </CardButtons>
      </CardImageWrapper>
      <CardContent>
        <CardTitle>{name}</CardTitle>
        {(department || industry) && (
          <CardIndustryAndDepartment>
            {industry && (
              <CardIndustry>
                <OfficeBuildingIcon size="small" />
                {industry}
              </CardIndustry>
            )}
            {department && (
              <CardDepartment>
                <UsersIcon size="small" />
                {department}
              </CardDepartment>
            )}
          </CardIndustryAndDepartment>
        )}
        {tags.length > 0 && (
          <GalleryDetailTagsWrapper>
            {tags.map((tag) => {
              return (
                <GalleryDetailTags key={tag.id}>
                  {t(`GalleryTags.${tag.title}`)}
                </GalleryDetailTags>
              );
            })}
          </GalleryDetailTagsWrapper>
        )}
        <CardDescription title={description}>{description}</CardDescription>
        {features && (
          <CardFeatures>
            {features.split('\n').map((feature) => (
              <CardFeature key={feature}>
                <CheckmarkIcon context="partials" aria-label="Checkmark" />
                {feature}
              </CardFeature>
            ))}
          </CardFeatures>
        )}
      </CardContent>
    </CardWrapper>
  );
}

export default Card;
