import styled from 'styled-components';

export const Container = styled.div`
  width: 38.5rem;
  margin: 0 auto;
  padding-top: 1.5rem;
`;

export const Section = styled.div`
  padding-top: 2rem;
`;
