import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useGalleryQuery, constants } from '@my-bb/common';
import {
  ErrorPage,
  MeProvider,
  MixpanelProvider,
  useMixpanel,
} from '@my-bb/common-components';
import { useLocationQuery } from '@my-bb/common-utils';
import { Header, List } from '../page-components/gallery';
import {
  GalleryDivider,
  GalleryListWrapper,
  HeaderContainer,
  HeaderWrapper,
  Page,
  PageHeader,
} from './styled';

interface QueryStringParams {
  page?: string;
  search?: string;
  sort?: string;
}

function Gallery(): JSX.Element {
  const { slug } = useParams();
  const [queryState, updateQueryState] = useLocationQuery<QueryStringParams>();
  const { search } = queryState;
  const { trackEvent } = useMixpanel();
  const { loading, data: galleryQuery } = useGalleryQuery({
    variables: { by: { slug } },
    skip: !slug,
  });
  const isValidGalleryRequest = galleryQuery?.gallery?.__typename === 'Gallery';

  const gallery =
    galleryQuery?.gallery?.__typename === 'Gallery'
      ? galleryQuery.gallery
      : undefined;

  const galleryItems =
    gallery && gallery.galleryItems.__typename === 'PagedGalleryItems'
      ? gallery.galleryItems.galleryItems
      : [];

  useEffect(() => {
    if (!gallery || !trackEvent) {
      return;
    }
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription && gallery.description) {
      metaDescription.setAttribute('content', gallery.description);
    }
    document.title = gallery.title;
    trackEvent('View gallery page', {
      Name: gallery.title,
      Slug: gallery?.slug,
      'Customer ID': gallery?.customerId,
    });
  }, [gallery, trackEvent]);

  const handleSearchChange = (term: string): void => {
    if ((term || search) && term !== search) {
      updateQueryState({
        ...queryState,
        page: '1',
        search: term,
      });
    }
  };

  if (!loading && !isValidGalleryRequest) {
    return <ErrorPage type={constants.UNAUTHORIZED_ERROR} />;
  }

  return (
    <MeProvider>
      <MixpanelProvider>
        <Page>
          {gallery && (
            <>
              <PageHeader hasImage={!!gallery.image}>
                <HeaderWrapper>
                  <HeaderContainer>
                    <Header
                      onSearchChange={handleSearchChange}
                      search={search}
                      gallery={gallery}
                    />
                  </HeaderContainer>
                </HeaderWrapper>
              </PageHeader>
              <GalleryDivider />
              <GalleryListWrapper>
                <List items={galleryItems || []} />
              </GalleryListWrapper>
            </>
          )}
        </Page>
      </MixpanelProvider>
    </MeProvider>
  );
}

export default Gallery;
