import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  ContactPersonAvatar,
  ContactPersonName,
  ContactPersonText,
  ContactPersonWrapper,
} from './styled';

interface Props {
  name: string;
  email?: string | null;
  avatar?: string | null;
}

export function ContactPerson({ name, email, avatar }: Props): JSX.Element {
  const { t } = useTranslation('main');
  return (
    <ContactPersonWrapper>
      {avatar && (
        <ContactPersonAvatar>
          <img alt={t('Gallery.contactPerson')} src={avatar} />
        </ContactPersonAvatar>
      )}
      {email && (
        <ContactPersonName>
          <a href={`mailto:${email}`} target="_blank" rel="noreferrer">
            {name}
          </a>
        </ContactPersonName>
      )}
      {!email && <ContactPersonName>{name}</ContactPersonName>}
      <ContactPersonText>{t('Gallery.contactPerson')}</ContactPersonText>
    </ContactPersonWrapper>
  );
}
