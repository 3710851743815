import React from 'react';

import { CloseIcon } from '@betty-blocks/design';

import { VideoContainer, VideoLayer, VideoCloseButton } from './styled';

interface Props {
  closeModal: () => void;
  videoUrl: string;
}

const getVideoAutoplayUrl = (input: string): string => {
  if (input.includes('?')) {
    return `${input}&autoplay=1`;
  }
  return `${input}?autoplay=1`;
};

export function VideoModal({ closeModal, videoUrl }: Props): JSX.Element {
  return (
    <VideoLayer>
      <VideoContainer>
        <iframe
          width="560"
          height="315"
          src={getVideoAutoplayUrl(videoUrl)}
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        />
        <VideoCloseButton
          icon={<CloseIcon size="medium" color="white" />}
          onClick={closeModal}
        />
      </VideoContainer>
    </VideoLayer>
  );
}
