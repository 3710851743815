import React, {
  useState,
  useContext,
  ChangeEvent,
  MouseEvent,
  FormEvent,
} from 'react';
import { useTranslation } from 'react-i18next';
import { goToLogin, useResetPasswordMutation } from '@my-bb/common';
import {
  toastQueueContext,
  PasswordIndicator,
  TextInput,
} from '@my-bb/common-components';
import { errorUtils } from '@my-bb/common-utils';
import { MainPage } from '../../components/MainPage';
import {
  InputWrapper,
  PasswordBiggerInput,
  ConfirmButton,
  Title,
  SubTitle,
} from '../../components/MainPage/styled';

interface Props {
  token: string;
  twoFactorEnabled: boolean;
}

interface ErrorFields {
  password?: string;
  twofactorCode?: string;
}

export function ResetPasswordForm({
  token,
  twoFactorEnabled,
}: Props): JSX.Element {
  const { t } = useTranslation('main');
  const { addToToastQueue } = useContext(toastQueueContext);
  const [passwordChanged, setPasswordChanged] = useState(false);
  const [password, setPassword] = useState('');
  const [twofactorCode, setTwofactorCode] = useState('');
  const [error, setError] = useState<ErrorFields>({});

  const handleChange = (evt: ChangeEvent<HTMLInputElement>): void => {
    const { value } = evt.target;
    setPassword(value);
    setError({ password: undefined });
  };

  const handleChangeCode = (evt: ChangeEvent<HTMLInputElement>): void => {
    const { value } = evt.target;
    setTwofactorCode(value);
    setError({ twofactorCode: undefined });
  };

  const navigateToLogin = (): void => {
    goToLogin();
  };

  const [resetPassword, { loading }] = useResetPasswordMutation({
    variables: {
      token,
      password,
      ...(twoFactorEnabled && { twofactorCode }),
    },
    onCompleted: (completeData) => {
      const mutError = errorUtils.extractError(completeData.resetPassword);

      if (!mutError.type) {
        setPasswordChanged(true);
        return;
      }

      if (Object.keys(mutError.fields).length) {
        setError({
          ...error,
          ...mutError.fields,
        });
        return;
      }

      addToToastQueue(errorUtils.toastErrorData(mutError));
    },
  });

  const isSubmitBtnActive = !error.password;
  const handleSubmit = (
    evt:
      | MouseEvent<HTMLButtonElement | HTMLAnchorElement>
      | FormEvent<HTMLFormElement>,
  ): void => {
    evt.preventDefault();
    if (!isSubmitBtnActive || loading) {
      return;
    }
    resetPassword();
  };

  if (passwordChanged) {
    return (
      <MainPage>
        <Title>{t('ResetPassword.confirmation.title')}</Title>
        <SubTitle color="grey700">
          {t('ResetPassword.confirmation.subtitle')}
        </SubTitle>
        <ConfirmButton type="button" size="large" onClick={navigateToLogin}>
          {t('ResetPassword.confirmation.complete')}
        </ConfirmButton>
      </MainPage>
    );
  }

  return (
    <MainPage>
      <Title>{t('ResetPassword.request.title')}</Title>
      <SubTitle color="grey700">{t('ResetPassword.request.subtitle')}</SubTitle>
      <form onSubmit={handleSubmit}>
        <InputWrapper>
          <PasswordBiggerInput
            size="large"
            label={t('ResetPassword.request.labels.password')}
            name="password"
            value={password}
            onChange={handleChange}
            error={error.password}
          />
        </InputWrapper>
        <PasswordIndicator value={password} />
        {twoFactorEnabled && (
          <InputWrapper className="mt-8" data-testid="twofactor-field">
            <TextInput
              label={t('TwoFactorModal.label')}
              value={twofactorCode}
              size="large"
              onChange={handleChangeCode}
              error={error.twofactorCode}
              name="twofactorCode"
              margin="0"
              className="h-14"
            />
          </InputWrapper>
        )}
        <ConfirmButton
          type="submit"
          primary
          size="large"
          onClick={handleSubmit}
          disabled={!isSubmitBtnActive}
        >
          {t('ResetPassword.request.complete')}
        </ConfirmButton>
      </form>
    </MainPage>
  );
}
