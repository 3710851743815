import React, { useState, Fragment } from 'react';

import { Box } from '@betty-blocks/design';
import { useTranslation } from 'react-i18next';
import { useMixpanel } from '@my-bb/common-components';
import { Cards } from '../styled';
import { VideoModal } from './VideoModal';
import { VideoCard } from './VideoCard';

import secondaryThumbnail from '../../../assets/graphics/secondaryVideoCardThumbnail.jpeg';

export function VideoSection() {
  const { t } = useTranslation('main');
  const { trackEvent } = useMixpanel();

  const [modal, setModal] = useState({
    toggled: false,
    title: '',
    video: '',
  });

  const videoCards = [
    {
      title: t('Dashboard.secondaryVideoTitle'),
      subTitle: t('Dashboard.secondaryVideoSubTitle'),
      video: 'https://www.youtube.com/embed/EiaO8xDSbVU',
      thumbnail: secondaryThumbnail,
    },
  ];

  function openModal(videoCard) {
    setModal({
      toggled: true,
      title: videoCard.title,
      video: videoCard.video,
    });
    trackEvent(`Open MyBB video: ${videoCard.title}`);
  }

  const closeModal = () => {
    setModal((prevState) => ({
      ...prevState,
      toggled: false,
    }));
  };

  return (
    <Box>
      <VideoModal
        isDisplayed={modal.toggled}
        title={modal.title}
        video={modal.video}
        closeModal={closeModal}
      />
      <Cards>
        {videoCards.map((videoCard, index) => {
          return (
            <Fragment key={index}>
              <VideoCard
                title={videoCard.title}
                subTitle={videoCard.subTitle}
                thumbnail={videoCard.thumbnail}
                handleClick={() => openModal(videoCard)}
              />
            </Fragment>
          );
        })}
      </Cards>
    </Box>
  );
}
