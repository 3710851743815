import React, { useRef, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Heading, Text, Button, DeleteButton } from '@betty-blocks/design';
import { mutations, queries } from '@my-bb/common';
import { useMutation, useQuery } from '@apollo/client';
import {
  FullPageLoader,
  Label,
  TextInput,
  toastQueueContext,
} from '@my-bb/common-components';
import { hooks, errorUtils } from '@my-bb/common-utils';
import {
  Section,
  Email,
  Avatar,
  ProfileButtons,
  FileInput,
  SubmitWrapper,
  AvatarNotice,
} from './styled';

export function ProfileSettings() {
  const { t } = useTranslation();
  const { addToToastQueue } = useContext(toastQueueContext);
  const fileRef = useRef();
  const [input, setInput] = useState({
    name: '',
    email: '',
    avatar: null,
    removeAvatar: false,
  });
  const [errors, setErrors] = useState({});
  const avatarPreview = hooks.usePreviewImage(input.avatar);
  const isGravatarUsed =
    typeof input.avatar === 'string' && input.avatar.includes('gravatar.com');
  const canRemoveAvatar = !isGravatarUsed && !!input.avatar;

  const { loading: initialDataIsLoading } = useQuery(queries.me, {
    onCompleted({ me: completedData }) {
      const { avatar, name, email } = completedData;
      setInput({
        avatar,
        name,
        email,
      });
    },
  });

  const [updateProfile, { loading: isUpdating }] = useMutation(
    mutations.updateProfile,
    {
      variables: {
        userInfo: {
          ...input,
          avatar: typeof input.avatar === 'string' ? undefined : input.avatar,
        },
      },
      onCompleted(completeData) {
        const error = errorUtils.extractError(completeData.updateProfile);

        if (!error.type) {
          addToToastQueue({
            type: 'success',
            body: t('main:ProfileSettings.successMsg'),
          });
          return;
        }

        if (Object.keys(error.fields).length) {
          setErrors({
            ...errors,
            ...error.fields,
          });
          return;
        }

        addToToastQueue(errorUtils.toastErrorData(error));
      },
    },
  );

  const handleInput = (e) => {
    const { name, value } = e.target;

    setErrors({
      ...errors,
      [name]: undefined,
    });

    setInput({
      ...input,
      [name]: value,
    });
  };

  const handleUpload = (e) => {
    const { name, files } = e.target;
    const file = files[0];

    setErrors({
      ...errors,
      [name]: undefined,
    });

    setInput({
      ...input,
      removeAvatar: false,
      [name]: file,
    });
  };

  const handleUploadClick = (e) => {
    e.currentTarget.blur();
    fileRef.current.click();
  };

  const handleClearAvatar = (e) => {
    e.currentTarget.blur();
    if (!canRemoveAvatar) {
      return;
    }

    setErrors({
      ...errors,
      avatar: undefined,
    });

    setInput({
      ...input,
      avatar: null,
      removeAvatar: true,
    });
  };

  const getAvatar = () => {
    if (avatarPreview) return avatarPreview;

    if (typeof input.avatar === 'string') {
      return input.avatar;
    }

    return undefined;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    updateProfile();
  };

  if (initialDataIsLoading) return <FullPageLoader />;

  return (
    <>
      <Heading level={2} margin="none" size="large">
        {t('main:SettingsTabs.profile')}
      </Heading>
      <Text color="grey700">{t('main:ProfileSettings.desc')}</Text>
      <form onSubmit={handleSubmit}>
        <Section>
          <Label>{t('main:ProfileSettings.uploadTitle')}</Label>
          <ProfileButtons>
            <Avatar size="lg" uri={getAvatar()} />
            <Button
              type="button"
              plain={false}
              onClick={handleUploadClick}
              label={t('main:ProfileSettings.uploadBtnText')}
            />
            <FileInput
              type="file"
              name="avatar"
              ref={fileRef}
              onChange={handleUpload}
              accept="image/jpg, image/jpeg, image/png"
            />
            <DeleteButton
              type="button"
              onClick={handleClearAvatar}
              disabled={!canRemoveAvatar}
              label={t('main:General.delete')}
            />
          </ProfileButtons>
          {errors.avatar ? (
            <AvatarNotice color="pink" size="small">
              {errors.avatar}
            </AvatarNotice>
          ) : (
            <AvatarNotice color="grey700" size="small">
              {t('common:General.uploadSizeNotice')}
            </AvatarNotice>
          )}
        </Section>
        <TextInput
          value={input.name}
          label={t('main:ProfileSettings.name')}
          name="name"
          size="large"
          onChange={handleInput}
          placeholder={t('main:ProfileSettings.name')}
          error={errors.name}
        />
        <Email>
          <TextInput
            value={input.email}
            label={t('main:ProfileSettings.email')}
            name="email"
            size="large"
            onChange={handleInput}
            placeholder={t('main:ProfileSettings.email')}
            error={errors.email}
          />
        </Email>
        <SubmitWrapper>
          <Button
            type="submit"
            loading={isUpdating}
            size="large"
            primary
            label={t('main:General.save')}
          />
        </SubmitWrapper>
      </form>
    </>
  );
}
