import React, { useEffect, useState } from 'react';
import { ApolloProvider } from '@apollo/client';
import type { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { checkTokenValidity, createClient } from '@my-bb/common';
import { FullPageLoader } from '@my-bb/common-components';

interface Props {
  children: JSX.Element;
  idpHint?: string | null;
}

export function ClientProvider({ children, idpHint }: Props): JSX.Element {
  const [apolloClient, setApolloClient] =
    useState<ApolloClient<NormalizedCacheObject>>();
  const [userValidated, setUserValidated] = useState(false);

  const getApolloClient = async (): Promise<void> => {
    const { client } = createClient();
    setApolloClient(client);
  };

  useEffect(() => {
    checkTokenValidity(idpHint).then((res) => {
      if (res === true) {
        setUserValidated(res);
        getApolloClient();
      }
    });
  }, []);

  if (!apolloClient || !userValidated) {
    return <FullPageLoader />;
  }

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
}
