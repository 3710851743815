import styled from 'styled-components';

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 1.5rem 0 2rem 2rem;
  & button:first-of-type {
    margin-right: 0.5rem;
  }
`;
