import React, { ReactNode } from 'react';
import { ApolloProvider } from '@apollo/client';
import { createAnonymousClient } from '@my-bb/common';

interface Props {
  children: ReactNode;
}

export function AnonymousClientProvider({ children }: Props): JSX.Element {
  const { client } = createAnonymousClient();

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}
