import React, { useState, useEffect, useContext, ChangeEvent } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import {
  useConvertInvitedProspectUserMutation,
  type ConvertInvitedProspectUserMutation,
  queries,
  routes,
  InvalidInputErrorFragFragment,
} from '@my-bb/common';
import { CheckBox } from '@betty-blocks/core-components';
import { useTranslation } from 'react-i18next';
import { Box, Text, Dropdown } from '@betty-blocks/design';
import { errorUtils } from '@my-bb/common-utils';
import {
  PasswordIndicator,
  useAnonymousMixpanel,
  toastQueueContext,
} from '@my-bb/common-components';
import {
  useValidation,
  extractAppIdentifier,
  extractOrgId,
  checkBrokenUrl,
} from '../../hooks';
import { OnboardingPage } from '../../components/OnboardingPage';
import {
  InputWrapper,
  BiggerFormField,
  BiggerInput,
  PasswordBiggerInput,
  ConfirmButton,
  Title,
  SubTitle,
} from '../../components/MainPage/styled';
import {
  StyledLabel,
  StyledInfoText,
  StyledLink,
  BiggerText,
  StyledFormField,
  StyledText,
} from './styled';

const { DASHBOARD } = routes;

interface UserForm {
  firstName: string | null;
  lastName: string | null;
  experience: string | null;
  intention: string | null;
  password: string | null;
  confirmPassword: string | null;
  newsletterConsent: boolean;
  agreementTerms: boolean;
}

export function InviteRegistrationForm(): JSX.Element {
  const { trackEvent, isLoaded } = useAnonymousMixpanel();
  const { addToToastQueue } = useContext(toastQueueContext);
  const location = useLocation();
  const navigate = useNavigate();
  const redirectToDashboard = `//${window.location.hostname}${DASHBOARD}`;
  const redirectUrlFromPath =
    new URLSearchParams(location.search).get('re') || '';

  const [redirectUrl, setRedirectUrl] = useState(redirectUrlFromPath);

  const orgId = extractOrgId(redirectUrlFromPath);
  const appIdentifier = extractAppIdentifier(redirectUrlFromPath);
  const isBrokenUrl = checkBrokenUrl(redirectUrlFromPath);
  const { token } = useParams();

  const [values, setValues] = useState<UserForm>({
    firstName: '',
    lastName: '',
    experience: '',
    intention: '',
    password: '',
    confirmPassword: '',
    newsletterConsent: false,
    agreementTerms: false,
  });

  const [errorKeys, setErrorKeys] = useState<UserForm>({
    firstName: null,
    lastName: null,
    experience: null,
    intention: null,
    password: null,
    confirmPassword: null,
    newsletterConsent: false,
    agreementTerms: false,
  });

  const privacyPolicyUrl = 'https://www.bettyblocks.com/privacy-policy';
  const termsAndConditionsUrl =
    'https://www2.bettyblocks.com/hubfs/Terms_of_Use-Trial_Version_Website_2022.1.0_EN.pdf';

  useEffect(() => {
    if (trackEvent) trackEvent('Open MyBB invitation register page');
  }, [trackEvent, isLoaded]);

  const { t } = useTranslation('main');
  const { requiredFieldValidation } = useValidation('InviteRegister');

  const isInputValid = (): boolean => {
    const requiredFieldErrors = Object.keys(errorKeys).map((key) => {
      return requiredFieldValidation(values, key);
    });

    const errorMessages = Object.assign({}, ...requiredFieldErrors);

    setErrorKeys(errorMessages);

    if (
      !errorMessages.firstName &&
      !errorMessages.lastName &&
      !errorMessages.experience &&
      !errorMessages.intention &&
      !errorMessages.password &&
      !errorMessages.confirmPassword &&
      !errorMessages.agreementTerms
    ) {
      return true;
    }
    return false;
  };

  const errorMessage = (key: string): string =>
    t(`InviteRegister.errors.${key}`);

  const getErrorMessage = (error: { field: string }): string | undefined => {
    const { field } = error;
    switch (field) {
      case 'registration':
      case 'userId':
        return errorMessage('registration');

      default:
        return undefined;
    }
  };

  const triggerErrorToast = (messages: (string | undefined)[]): void => {
    const message = messages.pop() || t('General.somethingWrong');
    addToToastQueue({
      type: 'failed',
      body: message,
    });
  };

  const handleError = (
    errors: InvalidInputErrorFragFragment | undefined,
  ): void => {
    if (errors?.inputErrors) {
      const errorMessages = errors.inputErrors.map((inputError) =>
        getErrorMessage(inputError),
      );
      triggerErrorToast(errorMessages);
    }
  };

  const [convertInvitedProspectUser] = useConvertInvitedProspectUserMutation({
    onCompleted: (completeData: ConvertInvitedProspectUserMutation) => {
      const mutError = errorUtils.extractError(
        completeData.convertInvitedProspectUser,
      );

      if (!mutError.type) {
        trackEvent('Submit Invitation Register page', {
          firstName: values.firstName,
          lastName: values.lastName,
          intention: values.intention,
          levelOfExperience: values.experience,
          terms: !!values.agreementTerms,
          newsletterConsent: values.newsletterConsent,
        });
        window.location.replace(
          isBrokenUrl ? redirectToDashboard : redirectUrl,
        );
        return;
      }

      if (
        Object.keys(mutError.fields).length &&
        completeData.convertInvitedProspectUser
      ) {
        handleError(
          completeData.convertInvitedProspectUser &&
            completeData.convertInvitedProspectUser.__typename ===
              'InvalidInputError'
            ? completeData.convertInvitedProspectUser
            : undefined,
        );
        setErrorKeys({
          ...errorKeys,
          ...mutError.fields,
        });
      }
    },
    onError: () => {
      addToToastQueue(
        errorUtils.toastErrorData({
          type: 'failed',
        }),
      );
    },
  });

  useQuery(queries.getProspectUserMembershipAndRole, {
    skip: !(orgId || appIdentifier),
    variables: {
      token,
      identifier: orgId !== undefined ? orgId : appIdentifier,
      membershipKey: orgId !== undefined ? 'organization' : 'application',
    },
    onCompleted: (completeData) => {
      const mutError = errorUtils.extractError(
        completeData.getProspectUserMembershipAndRole,
      );

      if (!mutError.type) {
        const { hasMembership, role } =
          completeData.getProspectUserMembershipAndRole;
        setRedirectUrl(
          (role === 'user' && !orgId) || !hasMembership
            ? redirectToDashboard
            : redirectUrl,
        );
      }

      if (Object.keys(mutError.fields).length) {
        setErrorKeys({
          ...errorKeys,
          ...mutError.fields,
        });
      }
    },
    onError: () => {
      addToToastQueue(
        errorUtils.toastErrorData({
          type: 'failed',
        }),
      );
    },
  });

  const handleCheckBoxChange = (value: boolean, key: string): void => {
    setValues((prevState) => ({
      ...prevState,
      [key]: value,
    }));
    setErrorKeys((prevState) => ({
      ...prevState,
      [key]: false,
    }));
  };

  const handleChange = (
    evt: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>,
  ): void => {
    const { name: targetName, value } = evt.target;
    setValues({ ...values, [targetName]: value });
    setErrorKeys({ ...errorKeys, [targetName]: null });
  };

  const handleSubmit = (): void => {
    if (isInputValid() && token) {
      convertInvitedProspectUser({
        variables: {
          input: {
            token,
            firstName: values.firstName || '',
            lastName: values.lastName || '',
            intention: values.intention || '',
            levelOfExperience: values.experience || '',
            terms: !!values.agreementTerms,
            newsletterConsent: values.newsletterConsent,
            password: values.password || '',
            passwordConfirmation: values.confirmPassword || '',
          },
        },
      });
    }
  };

  return (
    <OnboardingPage>
      <Title>{t('InviteRegister.title')}</Title>
      <SubTitle color="grey700">{t('InviteRegister.subTitle')}</SubTitle>
      <InputWrapper>
        <Box flex direction="row" gap="medium">
          <Box flex>
            <BiggerFormField label={t('InviteRegister.labels.firstName')}>
              <BiggerInput
                margin="0"
                size="large"
                name="firstName"
                value={values.firstName || ''}
                error={errorKeys.firstName || ''}
                onChange={handleChange}
              />
            </BiggerFormField>
          </Box>
          <Box flex>
            <BiggerFormField label={t('InviteRegister.labels.lastName')}>
              <BiggerInput
                margin="0"
                size="large"
                name="lastName"
                value={values.lastName || ''}
                error={errorKeys.lastName || ''}
                onChange={handleChange}
              />
            </BiggerFormField>
          </Box>
        </Box>
      </InputWrapper>
      <InputWrapper>
        <StyledLabel htmlFor="experience">
          {t('InviteRegister.labels.experience')}
        </StyledLabel>
        <StyledFormField error={errorKeys.experience}>
          <Dropdown
            name="experience"
            onChange={handleChange}
            size="xlarge"
            error={!!errorKeys.experience}
            defaultValue="default"
          >
            <option disabled value="default">
              {t('InviteRegister.experience.select')}
            </option>
            <option value="Nocoder">
              {t('InviteRegister.experience.noCoder')}
            </option>
            <option value="Developer">
              {t('InviteRegister.experience.dev')}
            </option>
            <option value="None">{t('InviteRegister.experience.none')}</option>
          </Dropdown>
        </StyledFormField>
      </InputWrapper>
      <InputWrapper>
        <StyledLabel htmlFor="intention">
          {t('InviteRegister.labels.intention')}
        </StyledLabel>
        <StyledFormField error={errorKeys.intention}>
          <Dropdown
            name="intention"
            onChange={handleChange}
            size="xlarge"
            error={!!errorKeys.intention}
            defaultValue="default"
          >
            <option disabled value="default">
              {t('InviteRegister.intention.select')}
            </option>
            <option value="try">{t('InviteRegister.intention.try')}</option>
            <option value="together">
              {t('InviteRegister.intention.together')}
            </option>
            <option value="alone">{t('InviteRegister.intention.alone')}</option>
            <option value="trained">
              {t('InviteRegister.intention.trained')}
            </option>
          </Dropdown>
        </StyledFormField>
      </InputWrapper>
      <InputWrapper>
        <BiggerFormField label={t('InviteRegister.labels.password')}>
          <PasswordBiggerInput
            size="large"
            name="password"
            value={values.password || ''}
            onChange={handleChange}
            error={errorKeys.password || ''}
          />

          <PasswordIndicator value={values.password || ''} />
        </BiggerFormField>
      </InputWrapper>
      <InputWrapper>
        <BiggerFormField label={t('InviteRegister.labels.confirmPassword')}>
          <PasswordBiggerInput
            size="large"
            name="confirmPassword"
            value={values.confirmPassword || ''}
            onChange={handleChange}
            error={errorKeys.confirmPassword || ''}
          />
        </BiggerFormField>
      </InputWrapper>
      <InputWrapper>
        <StyledInfoText
          size="small"
          color="grey700"
          margin={{ bottom: 'medium' }}
        >
          {t('InviteRegister.agreementInfo')}
        </StyledInfoText>
        <CheckBox
          id="newsletterConsent"
          onChange={() => {
            setValues((prevState) => ({
              ...prevState,
              newsletterConsent: !prevState.newsletterConsent,
            }));
          }}
          checked={values.newsletterConsent}
          label={
            (
              <StyledText color="grey900">
                {t('InviteRegister.labels.newsletterConsent')}
              </StyledText>
            ) as unknown as string
          }
        />
      </InputWrapper>
      <InputWrapper>
        <StyledFormField error={errorKeys.agreementTerms}>
          <CheckBox
            onChange={(value): void => {
              handleCheckBoxChange(value, 'agreementTerms');
            }}
            checked={!!values.agreementTerms}
            label={
              (
                <StyledText color="grey900">
                  {t('InviteRegister.labels.agreementLabel')}{' '}
                  <StyledLink
                    color="turquoise"
                    onClick={(e) => {
                      e.preventDefault();
                      window.open(termsAndConditionsUrl, '_blank');
                    }}
                  >
                    {t('InviteRegister.labels.termsAndConditions')}
                  </StyledLink>{' '}
                  {t('InviteRegister.labels.agreementAnd')}{' '}
                  <StyledLink
                    color="turquoise"
                    onClick={(e) => {
                      e.preventDefault();
                      window.open(privacyPolicyUrl, '_blank');
                    }}
                  >
                    {t('InviteRegister.labels.privacyPolicy')}
                  </StyledLink>{' '}
                  *
                </StyledText>
              ) as unknown as string
            }
          />
        </StyledFormField>
      </InputWrapper>
      <ConfirmButton
        fill
        primary
        color="turquoise"
        size="large"
        onClick={handleSubmit}
      >
        {t('InviteRegister.complete')}
      </ConfirmButton>
      <Text
        color="grey700"
        size="small"
        textAlign="center"
        margin={{ top: 'xsmall' }}
      >
        {t('InviteRegister.completeInfo')}
      </Text>
      <Box
        margin={{ top: 'medium', bottom: 'medium' }}
        alignSelf="center"
        direction="row"
      >
        <BiggerText>{t('InviteRegister.hasAccount')}</BiggerText>
        <Box>
          <StyledLink
            margin={{ left: 'xxsmall' }}
            color="turquoise"
            onClick={() => navigate('/')}
          >
            {t('InviteRegister.login')}
          </StyledLink>
        </Box>
      </Box>
    </OnboardingPage>
  );
}
