import React from 'react';
import { Outlet, useOutletContext, useParams } from 'react-router-dom';
import {
  useGalleryLookupQuery,
  constants,
  createAnonymousClient,
} from '@my-bb/common';
import {
  ErrorPage,
  FullPageLoader,
  AnonymousMixpanelProvider,
  MixpanelProvider,
  MeProvider,
} from '@my-bb/common-components';
import { ClientProvider } from '../ClientProvider/ClientProvider';
import { AnonymousClientProvider } from '../AnonymousClientProvider/AnonymousProvider';

interface ContextType {
  isLoggedIn: boolean;
}

export function GalleryDetection(): JSX.Element | null {
  const { slug = '' } = useParams();
  const { client } = createAnonymousClient();
  const { loading, data: galleryData } = useGalleryLookupQuery({
    variables: { slug },
    skip: slug === '',
    client,
  });

  if (loading) {
    return <FullPageLoader />;
  }

  if (galleryData?.lookupGallery.__typename === 'NotFoundError') {
    return <ErrorPage type={constants.NOT_FOUND_ERROR} />;
  }

  if (
    galleryData?.lookupGallery.__typename === 'AnonymousGallery' &&
    !galleryData.lookupGallery.public
  ) {
    return (
      <ClientProvider idpHint={galleryData.lookupGallery.customerIdpUuid || ''}>
        <MeProvider>
          <MixpanelProvider>
            <Outlet context={{ isLoggedIn: true }} />
          </MixpanelProvider>
        </MeProvider>
      </ClientProvider>
    );
  }

  return (
    <AnonymousMixpanelProvider>
      <AnonymousClientProvider>
        <Outlet context={{ isLoggedIn: false }} />
      </AnonymousClientProvider>
    </AnonymousMixpanelProvider>
  );
}

export function useLoggedIn(): ContextType {
  return useOutletContext<ContextType>();
}
