import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@betty-blocks/design';
import { Title, SubTitle } from '../MainPage/styled';

export function ErrorBlock({
  title,
  subTitle,
  buttonClickHandler,
  buttonLabel,
  disabled,
  loading,
}) {
  return (
    <>
      <Title>{title}</Title>
      <SubTitle color="grey700">{subTitle}</SubTitle>
      <Button
        primary
        disabled={disabled}
        loading={loading}
        size="large"
        onClick={buttonClickHandler}
        label={buttonLabel}
      />
    </>
  );
}

ErrorBlock.propTypes = {
  buttonClickHandler: PropTypes.func.isRequired,
  title: PropTypes.oneOfType([
    PropTypes.func.isRequired,
    PropTypes.string.isRequired,
  ]),
  subTitle: PropTypes.string.isRequired,
  buttonLabel: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  loading: PropTypes.bool.isRequired,
};
