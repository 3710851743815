import React, { useState, useContext, type ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { TOTP } from 'otpauth';
import QRcode from 'qrcode.react';
import {
  FormField,
  Heading,
  Input,
  Modal,
  Text,
} from '@betty-blocks/core-components';
import {
  useDisableTwoFactorMutation,
  useEnableTwoFactorMutation,
  useTwoFactorQuery,
} from '@my-bb/common';
import type {
  DisableTwoFactorMutation,
  EnableTwoFactorMutation,
} from '@my-bb/common';
import { toastQueueContext } from '@my-bb/common-components';
import { errorUtils, helpers } from '@my-bb/common-utils';

const inputMask = '### - ###';
const service = 'BETTY-BLOCKS';
const disabled = 'disabled';

interface Props {
  onClose: () => void;
  email: string;
  isEnabled: boolean;
  afterSuccess: () => void;
}

export function TwoFactorModal({
  onClose,
  email,
  isEnabled,
  afterSuccess,
}: Props): JSX.Element {
  const { t } = useTranslation('main');
  const { addToToastQueue } = useContext(toastQueueContext);
  const [code, setCode] = useState('');
  const [errors, setErrors] = useState<Record<string, string | undefined>>({});
  const confirmText = isEnabled ? disabled : 'confirm';
  const context = isEnabled && disabled;

  const { data } = useTwoFactorQuery({
    skip: isEnabled,
    fetchPolicy: 'network-only',
  });
  const { twoFactorSecret = '' } = data?.twoFactor || {};
  const otpuri = new TOTP({
    label: email,
    issuer: service,
    secret: twoFactorSecret,
  }).toString();

  const onCompleted = (
    response:
      | EnableTwoFactorMutation['enableTwoFactor']
      | DisableTwoFactorMutation['disableTwoFactor'],
  ): void => {
    const error = errorUtils.extractError(response);
    if (!error.type) {
      afterSuccess();
      setCode('');
      addToToastQueue({
        type: 'success',
        body: t('TwoFactorModal.successMsg', { context }),
      });
      onClose();
      return;
    }
    if (response?.__typename === 'UnprocessableEntityError') {
      addToToastQueue({
        type: 'failed',
        body: t('TwoFactorModal.failedMsg', { context }),
      });
      return;
    }
    if (Object.keys(error.fields).length) {
      setErrors({ ...errors, ...error.fields });
      return;
    }
    addToToastQueue(errorUtils.toastErrorData(error));
  };

  const [enable2FAuth, { loading: isEnabling }] = useEnableTwoFactorMutation({
    variables: { code, secret: twoFactorSecret },
    onCompleted: (response) => {
      onCompleted(response.enableTwoFactor);
    },
  });

  const [disable2FAuth, { loading: isDisabling }] = useDisableTwoFactorMutation(
    {
      variables: { code },
      onCompleted: (response) => {
        onCompleted(response.disableTwoFactor);
      },
    },
  );

  const confirmHandler = (): void => {
    const callback = isEnabled ? disable2FAuth : enable2FAuth;
    callback();
  };

  const confirmProps = {
    disabled: code.length < 6,
    loading: isEnabling || isDisabling,
  };

  const isDisabled = confirmProps.disabled || confirmProps.loading;

  const handleChange = (evt: ChangeEvent<HTMLInputElement>): void => {
    evt.preventDefault();
    const { value, name } = evt.target;
    setCode(value.replace(/[^\d]/g, ''));
    setErrors({ ...errors, [name]: undefined });
  };

  return (
    <Modal size="small" onClose={onClose}>
      <Modal.Header title={t('TwoFactorModal.title', { context })} />
      <Modal.Body>
        <>
          {!isEnabled && (
            <>
              <Text color="neutralGrey700" as="p">
                {t('TwoFactorModal.desc')}
              </Text>
              <div className="flex flex-col items-center mt-6 mb-6">
                <QRcode value={otpuri} renderAs="svg" level="M" size={156} />
                {twoFactorSecret && (
                  <Text className="pt-1">{twoFactorSecret}</Text>
                )}
              </div>
            </>
          )}
          {!isEnabled && (
            <Heading level={6} className="m-0">
              {t('TwoFactorModal.label')}
            </Heading>
          )}
          <Text color="neutralGrey700" as="p">
            {t('TwoFactorModal.helperText', { context })}
          </Text>
          <FormField
            label={t('TwoFactorModal.label')}
            errorText={errors.code}
            helperText={isEnabled ? t('TwoFactorModal.inputHelperText') : ''}
            className="block mt-4"
            size="large"
          >
            <Input
              type="text"
              context="default"
              size="large"
              name="code"
              placeholder={inputMask}
              value={helpers.formatToMask(code, inputMask)}
              onChange={handleChange}
            />
          </FormField>
        </>
      </Modal.Body>
      <Modal.Footer>
        <Modal.Footer.CancelButton onClick={onClose}>
          {t('TwoFactorModal.dismiss')}
        </Modal.Footer.CancelButton>
        <Modal.Footer.ActionButton
          variant="primary"
          context="default"
          disabled={isDisabled}
          onClick={confirmHandler}
          loading={confirmProps.loading}
        >
          {t(`TwoFactorModal.${confirmText}`)}
        </Modal.Footer.ActionButton>
      </Modal.Footer>
    </Modal>
  );
}
