import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@betty-blocks/design';
import { FormSection } from '../FormSection';
import { Main } from '../MainPage/styled';
import {
  OnboardingContent,
  VideoBox,
  VideoPlayer,
  StyledBox,
  StyledCheckmark,
  CheckmarkItem,
  Title,
  SecondaryTitle,
  SubTitle,
} from './styled';

import onboardingSectionBackground from '../../assets/graphics/onboardingRegisterBackground.svg';

interface Props {
  children: JSX.Element | JSX.Element[];
}

export function OnboardingPage({ children }: Props): JSX.Element {
  const { t } = useTranslation('main');
  const videoUrl = 'https://www.youtube.com/embed/EiaO8xDSbVU';
  const listItems = [
    t('OnboardingContent.listItemIntroduction'),
    t('OnboardingContent.listItemStepByStep'),
    t('OnboardingContent.listItemMainFeatures'),
    t('OnboardingContent.listItemLearningOpportunities'),
  ];

  return (
    <Main>
      <Box direction="row">
        <FormSection>{children}</FormSection>
        <OnboardingContent
          direction="column"
          basis="2/3"
          backgroundImage={onboardingSectionBackground}
        >
          <StyledBox>
            <Title>{t('OnboardingContent.title')}</Title>
            <SubTitle>{t('OnboardingContent.text')}</SubTitle>
          </StyledBox>
          <VideoBox id="videoContainer">
            <VideoPlayer
              src={videoUrl}
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowFullscreen
              title="Welcome to Betty Blocks"
            />
          </VideoBox>
          <StyledBox>
            <SecondaryTitle>{t('OnboardingContent.subTitle')}</SecondaryTitle>
            {listItems.map((listItem) => (
              <CheckmarkItem key={listItem}>
                <StyledCheckmark color="white" />
                {listItem}
              </CheckmarkItem>
            ))}
          </StyledBox>
        </OnboardingContent>
      </Box>
    </Main>
  );
}
