import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Box, Heading, Text, Button } from '@betty-blocks/design';
import { routes, constants } from '@my-bb/common';
import { useMixpanel } from '@my-bb/common-components';

import { HeaderBox, ButtonContainer } from './styled';

export function Header(): JSX.Element {
  const navigate = useNavigate();
  const { t } = useTranslation('main');
  const { trackEvent } = useMixpanel();

  const clickHandler = (): void => {
    trackEvent('Open MyBB template overview');
    navigate(routes.TEMPLATES, { state: { from: constants.DASHBOARD } });
  };

  return (
    <HeaderBox flex direction="row" justify="between">
      <Box>
        <Heading size="large">{t('Dashboard.startBuilding')}</Heading>
        <Text color="grey700">{t('Dashboard.startBuildingSubTitle')}</Text>
      </Box>
      <ButtonContainer alignSelf="center">
        <Button
          label={t('Dashboard.buttonAllTemplates')}
          size="large"
          onClick={clickHandler}
        />
      </ButtonContainer>
    </HeaderBox>
  );
}
