import React, { useRef, useState, useEffect } from 'react';
import { generatePath, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

import {
  CheckmarkIcon,
  OfficeBuildingIcon,
  PlayCircleIcon,
  UsersIcon,
} from '@betty-blocks/core-icons';

import { Box, Image, Heading, Button } from '@betty-blocks/design';

import { constants, routes, useGalleryItemQuery } from '@my-bb/common';
import type { GalleryImage } from '@my-bb/common';
import {
  Breadcrumbs,
  ErrorPage,
  FullPageLoader,
  useKeyDown,
  useMixpanel,
} from '@my-bb/common-components';
import { breadcrumbs, hooks } from '@my-bb/common-utils';

import {
  ContactPerson,
  ImageCarousel,
  ShowInterestedModal,
  VideoModal,
} from '../page-components/gallery-detail';

import {
  ButtonWrapper,
  ContentWrapper,
  GalleryDetailAside,
  GalleryDetailAsideBox,
  GalleryDetailContainer,
  GalleryDetailContent,
  GalleryDetailDepartment,
  GalleryDetailFeature,
  GalleryDetailFeatureHeader,
  GalleryDetailFeatures,
  GalleryDetailHeader,
  GalleryDetailHeaderContent,
  GalleryDetailHeaderDescription,
  GalleryDetailHeaderTitle,
  GalleryDetailIndustry,
  GalleryDetailIndustryAndDepartment,
  GalleryDetailOrganization,
  GalleryDetailOrganizationHeader,
  GalleryDetailOrganizationLogo,
  GalleryDetailOrganizationText,
  GalleryDetailTags,
  GalleryDetailTagsWrapper,
  ImageWrapper,
  InterestBodyText,
  InterestBox,
  InterestFooterText,
  Page,
  VideoBody,
  VideoPlay,
  VideoPlayIconWrapper,
  VideoTitle,
  VideoWrapper,
} from './styled';

import placeholderImage from '../assets/graphics/gallery-placeholder.svg';

export function GalleryDetail(): JSX.Element {
  const { t } = useTranslation('main');
  const videoModalRef = useRef<HTMLDivElement>(null);
  const [showModal, setShowModal] = useState(false);
  const [showVideoModal, setShowVideoModal] = useState(false);
  const { slug = '', galleryItemSlug = '' } = useParams();
  const { trackEvent } = useMixpanel();

  hooks.useOnClickOutside(videoModalRef, (): void => {
    setShowVideoModal(false);
  });

  useKeyDown(['Escape'], () => setShowVideoModal(false));

  const { data, loading } = useGalleryItemQuery({
    variables: {
      by: { slugLookup: { gallerySlug: slug, slug: galleryItemSlug } },
    },
  });

  useEffect(() => {
    const galleryItem =
      data?.galleryItem?.__typename === 'GalleryItem'
        ? data.galleryItem
        : undefined;

    if (!galleryItem || !trackEvent) {
      return;
    }
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription && galleryItem.description) {
      metaDescription.setAttribute('content', galleryItem.description);
    }
    document.title = `${galleryItem.name}`;
    trackEvent('View gallery detail page', {
      Name: galleryItem?.name,
      Slug: galleryItem?.slug,
      Gallery: galleryItem?.galleries
        .map(({ slug: gallerySlug }) => gallerySlug)
        .join(','),
      Organization: galleryItem?.organization?.name,
    });
  }, [data, trackEvent]);

  if (loading) {
    return <FullPageLoader />;
  }

  if (data?.galleryItem?.__typename === 'UnauthorizedError') {
    return <ErrorPage type={constants.UNAUTHORIZED_ERROR} />;
  }

  if (
    !data ||
    !data.galleryItem ||
    data.galleryItem.__typename === 'NotFoundError'
  ) {
    return <ErrorPage type={constants.NOT_FOUND_ERROR} />;
  }

  const {
    contactPerson,
    customer,
    name,
    department,
    description,
    features,
    id,
    image,
    images,
    industry,
    longDescription,
    organization,
    videoUrl,
    urlToApp,
    tags,
  } = data.galleryItem;

  const galleryImages =
    images && images?.length > 0
      ? images.reduce((acc: string[], cv: GalleryImage | null): string[] => {
          if (cv && cv.__typename === 'GalleryImage') {
            acc.push(cv.image || '');
          }
          return acc;
        }, [] as string[])
      : [];

  const { GALLERY, GALLERY_DETAIL } = routes;

  const galleryRoutes = [GALLERY, GALLERY + GALLERY_DETAIL];
  const params = {
    slug,
    galleryItemSlug,
    galleryItem: name,
  };

  const breadcrumbItems = galleryRoutes.map(
    (
      route: string,
      index: number,
    ): { text: string; link: string; params: breadcrumbs.Params } => {
      return {
        text: route,
        link:
          index === galleryRoutes.length - 1 ? '' : generatePath(route, params),
        params,
      };
    },
  );

  const handleVideoButtonClick = (): void => {
    setShowVideoModal(true);
  };

  return (
    <Page>
      {showModal && (
        <ShowInterestedModal
          closeModal={(): void => setShowModal(false)}
          galleryItemId={id}
          galleryItemName={name}
          galleryItemSlug={galleryItemSlug}
          galleryCustomerId={customer?.id}
        />
      )}
      {showVideoModal && videoUrl && (
        <div ref={videoModalRef}>
          <VideoModal
            closeModal={(): void => setShowVideoModal(false)}
            videoUrl={videoUrl}
          />
        </div>
      )}
      <GalleryDetailContainer>
        <GalleryDetailHeader>
          <GalleryDetailHeaderContent>
            <Box direction="row" height="100%">
              <ContentWrapper>
                <Box
                  pad={{ top: 'large', right: 'large', bottom: 'large' }}
                  height="100%"
                >
                  <Breadcrumbs items={breadcrumbItems} />

                  <Box
                    pad={{
                      top: 'large',
                      right: 'large',
                      bottom: 'large',
                    }}
                    height="100%"
                  >
                    <GalleryDetailHeaderTitle>{name}</GalleryDetailHeaderTitle>
                    {(department || industry) && (
                      <GalleryDetailIndustryAndDepartment>
                        {industry && (
                          <GalleryDetailIndustry>
                            <OfficeBuildingIcon size="small" />
                            {industry}
                          </GalleryDetailIndustry>
                        )}
                        {department && (
                          <GalleryDetailDepartment>
                            <UsersIcon size="small" />
                            {department}
                          </GalleryDetailDepartment>
                        )}
                      </GalleryDetailIndustryAndDepartment>
                    )}
                    {tags.length > 0 && (
                      <GalleryDetailTagsWrapper>
                        {tags.map((tag) => {
                          return (
                            <GalleryDetailTags key={tag.id}>
                              {t(`GalleryTags.${tag.title}`)}
                            </GalleryDetailTags>
                          );
                        })}
                      </GalleryDetailTagsWrapper>
                    )}

                    <GalleryDetailHeaderDescription>
                      {description}
                    </GalleryDetailHeaderDescription>
                    <ButtonWrapper>
                      <Button
                        primary
                        size="large"
                        onClick={(): void => setShowModal(true)}
                      >
                        {t('GalleryInterest.actionText')}
                      </Button>
                      {urlToApp && (
                        <Button
                          size="large"
                          href={urlToApp}
                          target="_blank"
                          plain={false}
                        >
                          {t('Gallery.viewPreview')}
                        </Button>
                      )}
                    </ButtonWrapper>
                    {videoUrl && (
                      <VideoWrapper>
                        <VideoTitle>{t('Gallery.videoTitle')}</VideoTitle>
                        <VideoBody>{t('Gallery.videoDescription')}</VideoBody>
                        <VideoPlay onClick={handleVideoButtonClick}>
                          <VideoPlayIconWrapper>
                            <PlayCircleIcon />
                          </VideoPlayIconWrapper>
                        </VideoPlay>
                      </VideoWrapper>
                    )}
                  </Box>
                </Box>
              </ContentWrapper>
              <ContentWrapper>
                {galleryImages && galleryImages.length > 1 ? (
                  <ImageCarousel images={galleryImages} />
                ) : (
                  <ImageWrapper pad="large" height="100%">
                    <Image
                      fit="cover"
                      fill
                      src={galleryImages[0] || image || placeholderImage}
                    />
                  </ImageWrapper>
                )}
              </ContentWrapper>
            </Box>
          </GalleryDetailHeaderContent>
        </GalleryDetailHeader>
        <GalleryDetailContent>
          <Box width="47rem">
            <Box pad={{ top: '1rem', bottom: '1rem' }}>
              <ReactMarkdown
                className="markdown-body"
                remarkPlugins={[remarkGfm]}
              >
                {longDescription || ''}
              </ReactMarkdown>
            </Box>
            <Box margin="2rem 0rem">
              <InterestBox>
                <Heading
                  size="large"
                  fill
                  margin={{ top: '0', bottom: '0.5rem' }}
                >
                  {t('GalleryInterest.title')}
                </Heading>
                <Box>
                  <InterestBodyText>
                    {t('GalleryInterest.body')}
                  </InterestBodyText>
                </Box>
                <Button
                  primary
                  size="large"
                  onClick={(): void => setShowModal(true)}
                  margin="0.25rem"
                >
                  {t('GalleryInterest.actionText')}
                </Button>
                <InterestFooterText>
                  {t('GalleryInterest.footer')}
                </InterestFooterText>
              </InterestBox>
            </Box>
          </Box>
          <GalleryDetailAside>
            {organization && (
              <GalleryDetailAsideBox>
                <GalleryDetailOrganization>
                  {organization.logo && (
                    <GalleryDetailOrganizationLogo>
                      <img src={organization.logo} alt="logo" />
                    </GalleryDetailOrganizationLogo>
                  )}
                  <GalleryDetailOrganizationHeader>
                    {organization?.name}
                  </GalleryDetailOrganizationHeader>
                  <GalleryDetailOrganizationText>
                    {t('Gallery.builtTheApp')}
                  </GalleryDetailOrganizationText>
                </GalleryDetailOrganization>
              </GalleryDetailAsideBox>
            )}
            {contactPerson?.name && (
              <GalleryDetailAsideBox>
                <ContactPerson
                  name={contactPerson?.name}
                  email={contactPerson?.email}
                  avatar={contactPerson?.avatar}
                />
              </GalleryDetailAsideBox>
            )}
            {features && (
              <GalleryDetailAsideBox>
                <GalleryDetailFeatures>
                  <GalleryDetailFeatureHeader>
                    {t('Gallery.keyFeatures')}
                  </GalleryDetailFeatureHeader>
                  {features.split('\n').map((feature) => (
                    <GalleryDetailFeature key={feature}>
                      <CheckmarkIcon
                        context="partials"
                        aria-label="Checkmark"
                      />
                      {feature}
                    </GalleryDetailFeature>
                  ))}
                </GalleryDetailFeatures>
              </GalleryDetailAsideBox>
            )}
          </GalleryDetailAside>
        </GalleryDetailContent>
      </GalleryDetailContainer>
    </Page>
  );
}
