import styled from 'styled-components';

export const Tooltip = styled.span`
  position: absolute;
  top: 50%;
  left: 100%;
  z-index: 13;
  display: flex;
  margin-left: 0.625rem;
  background: ${({ theme }): string => theme.global.colors.grey900};
  border: 0.1875rem;
  border-radius: 0.1875rem;
  box-shadow: 0 0.125rem 0.25rem 0 rgba(51, 51, 51, 0.41);
  transform: translateY(-50%);
  visibility: hidden;
  opacity: 0;
  user-select: none;
`;

export const Arrow = styled.span`
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: -0.3215rem;
  border-color: transparent
    ${({ theme }): string => theme.global.colors.grey900} transparent
    transparent;
  border-style: solid;
  border-width: 0.3215rem 0.3215rem 0.3215rem 0;
`;

export const TooltipContent = styled.span`
  display: flex;
  flex-direction: column;
  padding: 0.5rem 1rem;
  white-space: nowrap;
`;

export const Li = styled.li`
  position: relative;
  display: flex;
  justify-content: center;

  &.main-nav {
    cursor: pointer;

    &.active {
      background-color: ${({ theme }): string => theme.global.colors.grey400};
    }

    &.hover:not(.active),
    &:hover:not(.active) {
      background-color: ${({ theme }): string => theme.global.colors.grey200};
      transition: all 0.2s;
      transition-delay: 0.05s;
    }
  }

  &:hover {
    .light-up {
      border-color: ${({ theme }): string => theme.global.colors.turquoise500};
    }

    .custom-tooltip {
      visibility: visible;
      opacity: 1;
      transition:
        visibility 0ms 300ms linear,
        opacity 150ms 300ms ease-out;

      &:hover {
        display: none;
      }
    }
  }
`;
