import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { type ErrorPageType, queries, routes, constants } from '@my-bb/common';
import { ErrorPage, FullPageLoader } from '@my-bb/common-components';
import { errorUtils } from '@my-bb/common-utils';
import { RegistrationForm } from '../RegistrationForm';

const { DASHBOARD } = routes;

export function Registration(): JSX.Element {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const redirectToDashboard = `//${window.location.hostname}${DASHBOARD}`;
  const {
    loading,
    data,
    data: { checkInvitation } = {},
  } = useQuery(queries.checkInvitation, {
    variables: { jwt: token },
    onCompleted: (completeData) => {
      const { __typename: typename } = completeData.checkInvitation;
      if (typename === 'UnprocessableEntityError') {
        window.location.replace(redirectToDashboard);
      }
    },
    skip: !token,
  });

  const { type } = errorUtils.extractError(data, checkInvitation);

  if (!token) return <ErrorPage isFullPage type={constants.NOT_FOUND_ERROR} />;
  if (type) return <ErrorPage isFullPage type={type as ErrorPageType} />;
  if (loading) return <FullPageLoader />;

  return <RegistrationForm />;
}
