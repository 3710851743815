import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from '@my-bb/common-components';
import { Iframe, IframeContainer } from '../styled';

export function VideoModal({ isDisplayed, title, video, closeModal }) {
  if (!isDisplayed) {
    return null;
  }

  return (
    <Modal
      title={title}
      onClose={closeModal}
      width="50rem"
      height="fit-content"
    >
      <IframeContainer>
        <Iframe
          src={video}
          title={title}
          frameBorder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen
        />
      </IframeContainer>
    </Modal>
  );
}

VideoModal.propTypes = {
  isDisplayed: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  video: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired,
};
