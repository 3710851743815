import styled from 'styled-components';
import { Text, Heading, Box, CheckmarkIcon } from '@betty-blocks/design';

export const OnboardingContent = styled(Box)<{ backgroundImage: string }>`
  display: none;

  ${({ theme, backgroundImage }): string => `
  @media (min-width: ${theme.global.breakpoints.small.value}px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10rem 24rem;
    background-image: url(${backgroundImage});
    background-repeat: no-repeat;
    background-position: left;
    background-size: cover;

    @media (max-width: 2160px) {
      padding: 10rem 18rem;
      @media (max-width: 1920px) {
        padding: 10rem 16rem;
        @media (max-width: 1600px) {
          padding: 10rem 12rem;
          @media (max-width: 1280px) {
            padding: 10rem 6rem;
          }
        }
      }
    }

  }
`}
`;

export const Title = styled(Heading)`
  color: ${({ theme }): string => theme.global.colors.white};
  font-weight: 900;
  font-size: 2.5rem;
  font-family: Lato, sans-serif;
  line-height: 3rem;
  margin: 0;
`;

export const SecondaryTitle = styled(Heading)`
  margin-top: 2.5rem;
  margin-bottom: 1rem;
  color: white;
  font-weight: 900;
  font-size: 1.75rem;
  font-family: Lato, sans-serif;
  line-height: 2rem;
  text-align: left;
`;

export const SubTitle = styled(Text)`
  margin-top: 1rem;
  margin-bottom: 2.5rem;
  color: white;
  font-size: 1.25rem;
  line-height: 2rem;
  display: block;
`;

export const VideoBox = styled.div`
  position: relative;
  width: 100%;
  padding: 56.25% 0 0 0;
`;

export const VideoPlayer = styled.iframe<{ allowFullscreen?: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0.125rem solid ${({ theme }): string => theme.global.colors.white};
  border-radius: 0.25rem;
`;

export const StyledBox = styled(Box)`
  width: 100%;
`;

export const StyledCheckmark = styled(CheckmarkIcon)`
  margin-right: 0.5rem;
`;

export const CheckmarkItem = styled(Text)`
  padding-left: 0.25rem;
  color: ${({ theme }): string => theme.global.colors.white};
  font-size: 1.25rem;
  line-height: 2rem;
`;
