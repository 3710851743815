import styled from 'styled-components';
import { Text } from '@betty-blocks/design';
import { Avatar as AvatarBase } from '@my-bb/common-components';

export const Section = styled.div`
  margin: 2rem 0 1.5rem 0;
`;

export const Avatar = styled(AvatarBase)`
  margin-right: 1.5rem;
`;

export const ProfileButtons = styled.div`
  display: flex;
  align-items: flex-end;
  margin-top: 0.5rem;

  & > button + input + button {
    margin-left: 0.5rem;
  }
`;

export const Email = styled.div`
  margin-top: 1.5rem;
`;

export const FileInput = styled.input`
  display: none;
`;

export const SubmitWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 2.5rem;
`;

export const AvatarNotice = styled(Text)`
  margin-top: 0.25rem;
  display: block;
`;
