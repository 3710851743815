import { useEffect, useRef } from 'react';
import { GetEnvVariable } from '@my-bb/environment-variables';

const REACT_APP_GOOGLE_RECAPTCHA_TOKEN = GetEnvVariable(
  'REACT_APP_GOOGLE_RECAPTCHA_TOKEN',
);
const hasRecaptchaToken = Boolean(REACT_APP_GOOGLE_RECAPTCHA_TOKEN);
const RECAPTCHA_SCRIPT_ID = 'google-recaptcha-script';

export const useCaptcha = (action) => {
  const node = useRef(null);

  const executeCaptcha = (callback) => {
    if (hasRecaptchaToken) {
      window.grecaptcha.ready(() => {
        window.grecaptcha
          .execute(REACT_APP_GOOGLE_RECAPTCHA_TOKEN, {
            action,
          })
          .then((token) => {
            if (callback) callback(token);
          });
      });
    } else if (callback) callback('');
  };

  useEffect(() => {
    let { current } = node;
    if (hasRecaptchaToken) {
      current = document.createElement('script');
      current.src = `https://www.google.com/recaptcha/api.js?render=${REACT_APP_GOOGLE_RECAPTCHA_TOKEN}`;
      current.id = RECAPTCHA_SCRIPT_ID;
      current.async = true;
      current.defer = true;
      document.body.appendChild(current);
    }

    return () => {
      if (current) {
        document.body.removeChild(current);
        current = null;
      }
    };
  }, []);

  return executeCaptcha;
};
