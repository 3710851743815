import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Text } from '@betty-blocks/design';
import { redirectToLogin, middlewares } from '@my-bb/common';
import { Modal } from '@my-bb/common-components';

import { ButtonWrapper } from './styled';

export function InvalidAuthModal() {
  const [isActive, setIsActive] = useState(false);
  const { t } = useTranslation('main');
  middlewares.shared.showAuthModal = setIsActive;

  if (!isActive) {
    return null;
  }

  return (
    <Modal
      height="fit-content"
      title={t('InvalidAuthModal.title')}
      width="30rem"
    >
      <Text align="center" color="grey700">
        {t('InvalidAuthModal.content')}
      </Text>
      <ButtonWrapper>
        <Button
          onClick={redirectToLogin}
          label={t('InvalidAuthModal.confirm')}
          size="large"
          primary
        />
      </ButtonWrapper>
    </Modal>
  );
}
