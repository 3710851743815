import * as Sentry from '@sentry/react';
import { GetEnvVariable } from '@my-bb/environment-variables';

const SENTRY_DSN = GetEnvVariable('SENTRY_DSN');
const SENTRY_ENVIRONMENT = GetEnvVariable('SENTRY_ENVIRONMENT');

const sentryEnabled = Boolean(SENTRY_DSN);

export function InitSentry(): void {
  if (sentryEnabled) {
    Sentry.init({
      dsn: SENTRY_DSN,
      environment: SENTRY_ENVIRONMENT,
      integrations: [Sentry.replayIntegration()],

      // Capture Replay for 10% of all sessions,
      // plus for 100% of sessions with an error
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
    });
  }
}
