import { routes } from '@my-bb/common';

export const General = {
  somethingWrong: 'Oops, something went wrong!',
  delete: 'Delete',
  cancel: 'Cancel',
  save: 'Save changes',
  sortupdated_at: 'Newest',
  sortname: 'A-Z',
};

export const ErrorPage = {
  button: 'Back to the dashboard',
  note: 'Or create this app yourself!',
  footer: 'What is Betty Blocks?',
};

export const FailurePage = {
  expected: 'This application is not responding as expected',
  failure: 'Uhm… Something went terribly wrong here',
};

export const ForbiddenPage = {
  access: 'You do not have access to this application',
  failure: 'Uhm… Something went terribly wrong here',
};

export const NotFoundPage = {
  notFound: 'This application got lost in hyperspace',
  failure: 'Uhm… Something went terribly wrong here',
};

export const MainPage = {
  footer: 'Copyright © Betty Blocks {{year}}. All rights reserved',
};

export const ErrorBoundary = {
  title: 'Oops, something went wrong!',
  subtitle:
    'Please tell us what you were trying to do, and we’ll try to fix it.',
  callToAction: 'Give feedback',
  imageAlt: 'Fallen ice cream with a face',
};

export const Sidebar = {
  logout: 'Logout',
  profile: 'Account settings',
  account: 'Account',
  dashboard: 'Dashboard',
  organization: 'Organizations',
  application: 'Applications',
  blockStore: 'Block Store',
  community: 'Community',
  documentation: 'Documentation',
  productChanges: 'Product changes',
  status: 'Status',
  galleries: 'Galleries',
  customer: 'Company',
};

export const Dashboard = {
  community: 'Community',
  learning: 'Learning paths',
  documentation: 'Documentation',
  productChanges: 'Product changes',
  discover: 'Start with an intro tour',
  communityInfo: 'Share your questions or ideas with the community.',
  learningInfo:
    "Follow our tutorials on how to build with our platform's functionalities.",
  documentationInfo:
    'Find answers and troubleshooting tips while building your app.',
  productChangesInfo:
    "See what's new in Betty Blocks and what we have been releasing so far.",
  hi: 'Hi',
  welcome: 'Welcome to the My Betty Blocks dashboard.',
  learn: 'Learn Betty Blocks',
  discover_info:
    'Learn about My Betty Blocks and the features available to you for creating, managing, and enriching your applications.',
  learnSubtitle: 'We have resources to get you going as smoothly as possible.',
  primaryVideoTitle: 'Start with our intro video',
  primaryVideoSubTitle:
    'Learn more about the Betty Blocks fundamentals within 5 minutes.',
  secondaryVideoTitle: 'About Betty Blocks and citizen development',
  secondaryVideoSubTitle:
    'Discover the strategy behind citizen development and Betty Blocks.',
  startBuilding: 'Start building',
  startBuildingSubTitle:
    'Experience Betty Blocks with a guided building tour, or start a new application from scratch or a template.',
  buttonAllTemplates: 'View all templates',
  start: 'Start the tour',
};

export const SettingsTabs = {
  profile: 'Profile',
  password: 'Password',
  title: 'Account settings',
  back: 'Back',
};

export const GalleryTags = {
  lead_generation: 'Lead generation',
  dynamic_questionnaire: 'Dynamic questionnaire',
  quick_scan: 'Quick scan',
  compliance_tracking: 'Compliance tracking',
  client_intake: 'Client intake',
  client_portal: 'Client portal',
  portal: 'Portal',
  document_automation: 'Document automation',
  knowledge_center: 'Knowledge center',
  intranet: 'Intranet',
  ideation_portal: 'Ideation portal',
  information_portal: 'Information portal',
  workflow: 'Workflow',
  ai: 'AI',
  generative_ai: 'Generative AI',
  chatbot: 'Chatbot',
  risk_assessment: 'Risk assessment',
  employee_portal: 'Employee portal',
  checklist: 'Checklist',
  questionnaire: `Questionnaire`,
  project_management: `Project management`,
  dashboard: `Dashboard`,
  integrations: `Integrations`,
};

export const ProfileSettings = {
  desc: 'Settings to change your name, email address and profile picture',
  name: 'Name',
  email: 'Email address',
  uploadTitle: 'Profile picture',
  uploadBtnText: 'Upload new picture',
  successMsg: 'Profile settings updated.',
};

export const PasswordSettings = {
  title: 'Change your password',
  password: 'Current password',
  newPassword: 'New password',
  confirmPassword: 'Confirm new password',
  successMsg: 'Password updated.',
  twoFactor: {
    title: 'Two factor authentication',
    desc: 'Secure your account by enabling two factor authentication.',
    label: 'Enable two factor authentication',
  },
};

export const PublicRegister = {
  title: 'Register',
  subtitle: 'Get started by creating an account first',
  succesTitle: 'You got mail!',
  succesSubtitle:
    'We have sent an email to {{email}}. Follow the link provided in the email to complete registration.',
  succesSubtitleNoEmail:
    'We have sent an email. Follow the link provided in the email to complete registration.',
  labels: {
    firstName: 'First name *',
    lastName: 'Last name *',
    email: 'Business email *',
    emailPlaceholder: 'A valid email is required to verify your account',
    phone: 'Phone number *',
    companySize: 'Company size *',
    experience: 'Level of experience *',
    newsletterConsent:
      'I agree to receive Betty Blocks experience updates and resources',
    agreementLabel: `I agree to Betty Blocks'`,
    termsAndConditions: 'Terms of Use',
    agreementAnd: 'and',
    privacyPolicy: 'Privacy Policy',
  },
  errors: {
    email: {
      FIELD_NOT_ALLOWED: 'Only business emails are supported',
      ALREADY_TAKEN: 'An account with this email address already exists',
    },
    captcha: {
      INVALID:
        'Something went wrong. If you think this is a problem with us, please reach out to support@bettyblocks.com (Captcha error)',
    },
    required: {
      firstName: 'First name is required',
      lastName: 'Last name is required',
      email: 'Business email is required',
      phone: 'Phone number is required',
      companySize: 'Company size is required',
      experience: 'Level of experience is required',
      agreementTerms: 'You need to agree in order to register',
    },
    format: {
      email: 'Invalid email address',
      phone:
        'Numbers, spaces, and + - () symbols are allowed. Minimum 5 characters',
    },
  },
  hasAccount: 'Already have an account?',
  login: 'Sign in',
  companySize: {
    default: 'Select company size',
    xxsmall: '1 to 50',
    xsmall: '51 to 200',
    small: '201 to 500',
    medium: '501 to 1000',
    large: '1001 to 5000',
    xlarge: '5001 to 10000',
    xxlarge: '10000+',
  },
  experience: {
    select: 'Do you have development experience?',
    noCoder: 'No-code / Low-code experience',
    dev: 'Development experience',
    none: 'No experience',
  },
  agreementInfo:
    'To make sure you get the best and most complete Betty Blocks experience, we need to send you additional content and materials. We will not use your address for any other purposes.',
  completeInfo:
    '* By submitting this form, you give consent to Betty Blocks to store your information above to generate your account.',
  complete: 'Register',
};

export const InviteRegister = {
  title: 'Register',
  subTitle: 'Get started by creating an account first',
  labels: {
    firstName: 'First name *',
    lastName: 'Last name *',
    experience: 'Level of experience *',
    intention: 'What brings you to Betty Blocks? *',
    password: 'Password *',
    confirmPassword: 'Confirm password *',
    newsletterConsent:
      'I agree to receive Betty Blocks experience updates and resources',
    agreementLabel: `I agree to Betty Blocks'`,
    termsAndConditions: 'Terms of Use',
    agreementAnd: 'and',
    privacyPolicy: 'Privacy Policy',
  },
  errors: {
    registration: 'You have already registered.',
    captcha: {
      INVALID:
        'Something went wrong. If you think this is a problem with us, please reach out to support@bettyblocks.com (Captcha error)',
    },
    required: {
      firstName: 'First name is required',
      lastName: 'Last name is required',
      experience: 'Level of experience is required',
      intention: 'We would like to know why you are going to use the platform',
      password: 'Password is required',
      confirmPassword: 'Confirm password is required',
      agreementTerms: 'You need to agree in order to register',
    },
  },
  hasAccount: 'Already have an account?',
  login: 'Sign in',
  experience: {
    select: 'Do you have development experience?',
    noCoder: 'No-code / Low-code experience',
    dev: 'Development experience',
    none: 'No experience',
  },
  intention: {
    select: 'What brings you to Betty Blocks?',
    try: 'I am just trying out the platform',
    together: 'I will be building an application with someone',
    alone: 'I will be building on my own',
    trained: 'I will be trained to use the platform',
  },
  agreementInfo:
    'To make sure you get the best and most complete Betty Blocks experience, we need to send you additional content and materials. We will not use your address for any other purposes.',
  completeInfo:
    '* By submitting this form, you give consent to Betty Blocks to store your information above to generate your account.',
  complete: 'Complete registration',
};

export const Register = {
  title: 'Register',
  subtitle: 'Get started by creating an account first',
  labels: {
    name: 'Full name',
    password: 'Password',
  },
  complete: 'Complete registration',
};

export const SetPassword = {
  invalidToken: {
    title: 'Email unknown',
    subTitle:
      'This email is unknown to us, return to the register page to create an account.',
    buttonLabel: 'Return to register',
  },
  expiredToken: {
    title: 'This link is expired',
    subTitle: 'Click on resend so that we can email you a new password link.',
    buttonLabel: 'Resend email',
  },
  title: 'Password setup',
  subtitle: 'Finish the registration by setting up a password.',
  labels: {
    password: 'Password',
    confirmPassword: 'Confirm password',
  },
  complete: 'Finish registration',
};

export const PasswordForgotten = {
  request: {
    title: 'Forgot password',
    subtitle: 'We will send you a link to reset your password.',
    labels: {
      email: 'Email',
    },
    complete: 'Reset password',
  },
  confirmation: {
    title: 'Email sent',
    subtitle: `We've sent a password reset link to your email address.`,
    complete: 'Return to login',
  },
};

export const ResetPassword = {
  request: {
    title: 'Enter new password',
    subtitle: 'Enter and set your new password',
    labels: {
      password: 'Password',
    },
    complete: 'Set new password',
    info: 'information',
  },
  confirmation: {
    title: 'Success!',
    subtitle: 'Your password was succesfully updated.',
    complete: 'Return to login',
  },
};

export const TwoFactorModal = {
  title: 'Set up two factor authentication',
  title_disabled: 'Disable two factor authentication',
  desc: 'Scan the QR code or enter the code manually with an authenticator application on your phone.',
  label: 'Verification code',
  helperText:
    'After scanning the QR code, the authenticator application will display a 6-digit verification code. Fill in this code to enable the two factor authentication.',
  helperText_disabled:
    'Fill in the 6-digit verification code provided by an authentication application to disable two factor authentication.',
  inputHelperText:
    'Please delete the entry from your authenticator after disabling two factor authentication.',
  confirm: 'Enable',
  disabled: 'Disable',
  dismiss: 'Cancel',
  successMsg: 'Two factor authentication successfully enabled',
  successMsg_disabled: 'Two factor authentication successfully disabled',
  failedMsg:
    'Enabling two factor authentication failed, reload the page and try again',
  failedMsg_disabled:
    'Disabling two factor authentication failed, reload the page and try again',
};

export const Routes = {
  [routes.ORGANIZATIONS]: 'Organizations',
  [routes.ORGANIZATIONS + routes.ORGANIZATION_DETAILS]: '{{organization}}',
  [routes.ORGANIZATION_SETTINGS]: 'Settings of {{organization}}',
  [routes.ORGANIZATION_USERS]: 'User management',
  [routes.ORGANIZATION_ROLES]: 'Roles & Permissions',
  [routes.APPLICATIONS]: 'Applications',
  [routes.APPLICATIONS + routes.APPLICATION_DETAIL]: '{{mainApplication}}',
  [routes.MERGE_PLAN]: 'Merge overview',
  [routes.REBASE_PLAN]: 'Updates from {{application}}',
  [routes.ROLLBACK_PLAN]: 'Rollback overview',
  [routes.APPLICATION_SETTINGS]: 'Settings of {{application}}',
  [routes.MAIN_APPLICATION_SETTINGS]: 'Settings of {{application}}',
  [routes.SANDBOX_APPLICATION_SETTINGS]: 'Settings of {{application}}',
  [routes.APPLICATIONS + routes.USER_MANAGEMENT]: 'Users of {{application}}',
  [routes.BLOCK_STORE]: 'Block Store',
  [routes.BLOCK_STORE + routes.BLOCK_DETAIL]: '{{name}}',
  [routes.BLOCK_STORE + routes.BLOCK_DETAIL + routes.BLOCK_DETAIL_DEV]:
    '{{name}} Dev',
  [routes.MONITOR]: 'Application Monitoring',
  [routes.GALLERY]: 'App Gallery',
  [routes.GALLERY + routes.GALLERY_DETAIL]: '{{galleryItem}}',
  [routes.CUSTOMER]: 'Company',
  [routes.CUSTOMER + routes.CUSTOMER_SETTINGS]: 'Settings',
  [routes.MERGE_HISTORY]: 'Merge history of {{application}}',
};

export const OnboardingContent = {
  title: 'Get the Betty Blocks experience',
  text: 'Join the Betty Blocks platform and experience the power of no-code in just a few minutes. Follow a guided tour, see the core elements of the platform and find out how to make it work for you and your organization.',
  subTitle: `We'll cover:`,
  listItemIntroduction: `An introduction to Betty Blocks' no-code platform`,
  listItemStepByStep: 'Step-by-step building tours',
  listItemMainFeatures:
    'The main features used to build application with no-code',
  listItemLearningOpportunities:
    'Learning opportunities to continuously improve your skills',
};

export const InvalidAuthModal = {
  confirm: 'Sign back in',
  title: 'You are not signed in',
  content: 'Your session has expired due to an extended period of activity.',
};

export const Gallery = {
  paginationSubject: 'item',
  paginationSubject_plural: 'items',
  viewPreview: 'View live demo',
  videoTitle: 'Watch a presentation',
  videoDescription: 'An in-depth walkthrough within 5 minutes',
  modalClose: 'Close',
  showDetails: 'View details',
  keyFeatures: 'Key features',
  builtTheApp: 'Built the application',
  contactPerson: 'Contact person',
};

export const GalleryInterest = {
  title: 'Interested in this app?',
  body: `Interested in unlocking the full potential of this application too? Elevate your game with our powerful solution. Express your interest, and we'll ensure you get hooked up with the product!`,
  actionText: 'More info',
  footer: 'We will contact you as soon as possible.',
};

export const GalleryInterestModal = {
  title: 'Interested in this app?',
  titleSent: 'Thank you for expressing your interest',
  body: {
    main: `Interested in unlocking the full potential of this application
  too? Elevate your game with our powerful solution. Express
  your interest, and we'll ensure you get hooked up with
  the product!`,
    extendedEmail: `Simply leave your email address and we will contact you as soon as possible.`,
    sent: `We appreciate your inquiry and would like to assure you that we've received your information.
We'll be reaching out to you as soon as possible with further details and assistance.
We value your time and look forward to connecting with you promptly.`,
  },
  moreInfo: {
    label: 'More info about',
    gettingStarted: 'Getting Started',
    implementation: 'Implementation',
    pricing: 'Pricing',
    other: 'Other',
  },
  emailLabel: 'Your email address',
  helperEmail:
    'Submit your email address to stay updated (non-binding and free of charge).',
  formSubmit: 'Send request',
};
