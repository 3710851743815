import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { mutations, routes } from '@my-bb/common';
import { useTranslation } from 'react-i18next';
import {
  PasswordIndicator,
  useAnonymousMixpanel,
  toastQueueContext,
} from '@my-bb/common-components';
import { errorUtils } from '@my-bb/common-utils';
import {
  InputWrapper,
  PasswordBiggerInput,
  ConfirmButton,
  Title,
  SubTitle,
  BiggerFormField,
} from '../MainPage/styled';

export function Form() {
  const [values, setValues] = useState({
    password: '',
    passwordConfirmation: '',
  });

  const [errors, setErrors] = useState({
    password: undefined,
    passwordConfirmation: undefined,
  });
  const { t } = useTranslation('main');
  const { addToToastQueue } = useContext(toastQueueContext);

  const { trackEvent, isLoaded } = useAnonymousMixpanel();
  useEffect(() => {
    if (trackEvent) trackEvent('Open MyBB Set password page');
  }, [trackEvent, isLoaded]);

  const { token } = useParams();
  const navigate = useNavigate();
  const { password, passwordConfirmation } = values;

  const [convertProspectUser, { loading }] = useMutation(
    mutations.convertProspectUser,
    {
      variables: {
        input: {
          token,
          password,
          passwordConfirmation,
        },
      },
      onCompleted: (completeData) => {
        const mutError = errorUtils.extractError(
          completeData.convertProspectUser,
        );

        if (!mutError.type) {
          navigate(routes.DASHBOARD);
          trackEvent('Click MyBB Set Password button');
          return;
        }

        if (Object.keys(mutError.fields).length) {
          setErrors({
            ...errors,
            ...mutError.fields,
          });
        }
      },
      onError: () => {
        addToToastQueue(
          errorUtils.toastErrorData({
            type: 'failed',
          }),
        );
      },
    },
  );

  const handleChange = (evt) => {
    const { name: targetName, value } = evt.target;
    setValues({ ...values, [targetName]: value });
    setErrors({ ...errors, [targetName]: undefined });
  };

  const handleSubmit = () => {
    convertProspectUser();
  };

  return (
    <>
      <Title>{t('SetPassword.title')}</Title>
      <SubTitle color="grey700">{t('SetPassword.subtitle')}</SubTitle>
      <InputWrapper>
        <BiggerFormField label={t('SetPassword.labels.password')}>
          <PasswordBiggerInput
            size="large"
            name="password"
            value={password}
            error={errors.password}
            onChange={handleChange}
          />
          <PasswordIndicator value={password} />
        </BiggerFormField>
      </InputWrapper>
      <InputWrapper>
        <BiggerFormField label={t('SetPassword.labels.confirmPassword')}>
          <PasswordBiggerInput
            size="large"
            name="passwordConfirmation"
            value={passwordConfirmation}
            onChange={handleChange}
            error={errors.password}
          />
        </BiggerFormField>
      </InputWrapper>
      <ConfirmButton
        primary
        size="large"
        color="turquoise"
        type="submit"
        onClick={handleSubmit}
        disabled={loading}
      >
        {t('SetPassword.complete')}
      </ConfirmButton>
    </>
  );
}
