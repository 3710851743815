import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Heading, Button, Toggle } from '@betty-blocks/design';
import { useMutation, useQuery } from '@apollo/client';
import { mutations, queries } from '@my-bb/common';
import {
  PasswordInput,
  Field,
  BtnWrapper,
  Description,
  Label,
  Divider,
  TextInput,
  toastQueueContext,
} from '@my-bb/common-components';
import { errorUtils } from '@my-bb/common-utils';
import { Indicator, TwoFactorSection, dividerStyle } from './styled';
import { TwoFactorModal } from '../TwoFactorModal';

const initialInput = {
  password: '',
  currentPassword: '',
  twofactorCode: '',
};

export function PasswordSettings() {
  const { t } = useTranslation('main');
  const { addToToastQueue } = useContext(toastQueueContext);
  const [errors, setErrors] = useState({});
  const [input, setInput] = useState(initialInput);
  const [showTwoFactorModal, setShowTwoFactorModal] = useState(false);
  let user = { twoFactorEnabled: false };

  const { data, refetch } = useQuery(queries.me, {
    fetchPolicy: 'network-only',
  });

  const resp = data || {};
  user = { ...user, ...resp.me };

  const [updatePassword] = useMutation(mutations.updatePassword, {
    variables: {
      currentPassword: input.currentPassword,
      password: input.password,
      ...(user.twoFactorEnabled && { twofactorCode: input.twofactorCode }),
    },
    onCompleted(completeData) {
      const error = errorUtils.extractError(completeData.updatePassword);

      if (!error.type) {
        addToToastQueue({
          type: 'success',
          body: t('PasswordSettings.successMsg'),
        });
        setInput(initialInput);
        return;
      }

      if (Object.keys(error.fields).length) {
        setErrors({
          ...errors,
          ...error.fields,
        });
        return;
      }

      addToToastQueue(errorUtils.toastErrorData(error));
    },
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    setErrors({
      ...errors,
      [name]: undefined,
    });

    setInput({
      ...input,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    updatePassword();
  };

  const closeTwoFactorModal = () => setShowTwoFactorModal(false);
  const handleToggleChange = (e) => {
    e.preventDefault();
    setShowTwoFactorModal(true);
  };

  return (
    <>
      {showTwoFactorModal && (
        <TwoFactorModal
          email={user.email}
          onClose={closeTwoFactorModal}
          afterSuccess={refetch}
          isEnabled={user.twoFactorEnabled}
        />
      )}
      <form onSubmit={handleSubmit}>
        <Heading level={2} size="large" margin="none">
          {t('PasswordSettings.title')}
        </Heading>
        <Field>
          <PasswordInput
            value={input.currentPassword}
            error={errors.currentPassword}
            name="currentPassword"
            onChange={handleChange}
            label={t('PasswordSettings.password')}
            size="large"
          />
        </Field>
        <Field>
          <PasswordInput
            value={input.password}
            error={errors.password}
            name="password"
            onChange={handleChange}
            label={t('PasswordSettings.newPassword')}
            size="large"
          />
        </Field>
        <Indicator value={input.password} />
        {user.twoFactorEnabled && (
          <Field>
            <TextInput
              label={t('TwoFactorModal.label')}
              value={input.twofactorCode}
              size="large"
              onChange={handleChange}
              error={errors.code}
              name="twofactorCode"
              margin="0"
            />
          </Field>
        )}
        <BtnWrapper>
          <Button primary type="submit" size="large">
            {t('PasswordSettings.confirmPassword')}
          </Button>
        </BtnWrapper>
      </form>
      <Divider customStyle={dividerStyle} />
      <TwoFactorSection>
        <Heading level={2} margin="none" size="large">
          {t('PasswordSettings.twoFactor.title')}
        </Heading>
        <Description color="grey700">
          {t('PasswordSettings.twoFactor.desc')}
        </Description>
        <Field>
          <Label>{t('PasswordSettings.twoFactor.label')}</Label>
          <div>
            <Toggle
              size="large"
              name="twoFactor"
              checked={user.twoFactorEnabled}
              onChange={handleToggleChange}
            />
          </div>
        </Field>
      </TwoFactorSection>
    </>
  );
}
