import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useLocation } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import {
  MultiImageIcon,
  OfficeBuildingIcon,
  OrganisationsIcon,
} from '@betty-blocks/core-icons';
import {
  GridIcon,
  BlockStoreIcon,
  Forum,
  Documentation,
  NewTabIcon,
  Box,
  Status,
  Logout,
  ProductChanges,
} from '@betty-blocks/design';

import { queries, routes } from '@my-bb/common';
import { hooks } from '@my-bb/common-utils';

import bettyIcon from '../../assets/graphics/bettyIcon.svg';
import { SidebarItem } from '../SidebarItem';
import {
  DashboardSidebarItem,
  Container,
  NavList,
  DropOut,
  ExternalLink,
  Link,
  LinkContent,
  LinkTitle,
  LinkText,
  LinkDescription,
  LogoIcon,
  UserIcon,
  NavItem,
} from './styled';

const {
  DASHBOARD,
  LOGOUT,
  SETTINGS,
  ORGANIZATIONS,
  APPLICATIONS,
  BLOCK_STORE,
  BETTY_COMMUNITY,
  BETTY_DOCUMENTATION,
  BETTY_PRODUCT_CHANGES,
  BETTY_STATUS,
  GALLERIES,
  CUSTOMER_BASE,
} = routes;
export function Sidebar(): JSX.Element {
  const [isVisible, setIsVisible] = useState(false);
  const location = useLocation();

  const isActive =
    location.pathname === '/settings/profile' ||
    location.pathname === '/settings/password';

  const isActiveCompanyRoute = location.pathname.includes(CUSTOMER_BASE);

  const { data } = useQuery(queries.me);
  const { me } = data || {};
  const user = me || {};
  const { t } = useTranslation('main');
  const ref = useRef<HTMLUListElement>(null);

  const { permissions: userPermissions } = user || {};

  const handleClick = (): void => {
    setIsVisible((s): boolean => !s);
  };

  hooks.useOnClickOutside(ref, (): void => setIsVisible(false));

  const isAllowedToSeeCompany =
    userPermissions?.canGotoCustomer || userPermissions?.isBettyManager;

  return (
    <Container>
      <NavList>
        <DashboardSidebarItem tooltipText={t('Sidebar.dashboard')}>
          <NavItem end to={DASHBOARD}>
            <LogoIcon src={bettyIcon} alt="betty icon" />
          </NavItem>
        </DashboardSidebarItem>
        {isAllowedToSeeCompany && (
          <SidebarItem tooltipText={t('Sidebar.customer')}>
            <NavItem active={isActiveCompanyRoute} to={CUSTOMER_BASE}>
              <OfficeBuildingIcon size="large" />
            </NavItem>
          </SidebarItem>
        )}
        <SidebarItem tooltipText={t('Sidebar.organization')}>
          <NavItem to={ORGANIZATIONS}>
            <OrganisationsIcon context="neutral" size="large" />
          </NavItem>
        </SidebarItem>
        <SidebarItem tooltipText={t('Sidebar.application')}>
          <NavItem to={APPLICATIONS}>
            <GridIcon color="grey700" />
          </NavItem>
        </SidebarItem>
        {userPermissions?.canCreateGalleryItem ? (
          <SidebarItem tooltipText={t('Sidebar.galleries')}>
            <NavItem to={GALLERIES}>
              <MultiImageIcon size="large" />
            </NavItem>
          </SidebarItem>
        ) : null}
        <SidebarItem tooltipText={t('Sidebar.blockStore')}>
          <NavItem to={BLOCK_STORE}>
            <BlockStoreIcon color="grey700" />
          </NavItem>
        </SidebarItem>
      </NavList>

      <NavList ref={ref}>
        <SidebarItem
          onClick={handleClick}
          tooltipText={t('Sidebar.account')}
          className={`main-nav ${isVisible ? 'hover' : ''} ${
            isActive ? 'active' : ''
          }`}
          isActive={isVisible}
        >
          <UserIcon uri={user.avatar} />
          <DropOut isVisible={isVisible}>
            <SidebarItem>
              <Link to={generatePath(SETTINGS, { page: 'profile' })}>
                <UserIcon uri={user.avatar} isProfile />
                <LinkContent isProfile>
                  <LinkDescription color="grey700" isProfile>
                    {user.name || ''}
                  </LinkDescription>
                  <LinkText isProfile>{t('Sidebar.profile')}</LinkText>
                </LinkContent>
              </Link>
            </SidebarItem>
            <SidebarItem>
              <ExternalLink
                href={BETTY_COMMUNITY}
                target="_blank"
                rel="noopener noreferrer"
              >
                <LinkContent>
                  <LinkTitle>
                    <Forum color="grey700" />
                    <LinkText>{t('Sidebar.community')}</LinkText>
                    <Box className="link-icon" margin={{ left: '0.25rem' }}>
                      <NewTabIcon color="grey900" />
                    </Box>
                  </LinkTitle>
                </LinkContent>
              </ExternalLink>
            </SidebarItem>
            <SidebarItem>
              <ExternalLink
                href={BETTY_DOCUMENTATION}
                target="_blank"
                rel="noopener noreferrer"
              >
                <LinkContent>
                  <LinkTitle>
                    <Documentation color="grey700" />
                    <LinkText>{t('Sidebar.documentation')}</LinkText>
                    <Box className="link-icon" margin={{ left: '0.25rem' }}>
                      <NewTabIcon color="grey900" />
                    </Box>
                  </LinkTitle>
                </LinkContent>
              </ExternalLink>
            </SidebarItem>
            <SidebarItem>
              <ExternalLink
                href={BETTY_PRODUCT_CHANGES}
                target="_blank"
                rel="noopener noreferrer"
              >
                <LinkContent>
                  <LinkTitle>
                    <ProductChanges color="grey700" />
                    <LinkText>{t('Sidebar.productChanges')}</LinkText>
                    <Box className="link-icon" margin={{ left: '0.25rem' }}>
                      <NewTabIcon color="grey900" />
                    </Box>
                  </LinkTitle>
                </LinkContent>
              </ExternalLink>
            </SidebarItem>
            <SidebarItem>
              <ExternalLink
                href={BETTY_STATUS}
                target="_blank"
                rel="noopener noreferrer"
              >
                <LinkContent>
                  <LinkTitle>
                    <Status color="grey700" />
                    <LinkText>{t('Sidebar.status')}</LinkText>
                    <Box className="link-icon" margin={{ left: '0.25rem' }}>
                      <NewTabIcon color="grey900" />
                    </Box>
                  </LinkTitle>
                </LinkContent>
              </ExternalLink>
            </SidebarItem>
            <SidebarItem>
              <Link to={LOGOUT}>
                <Logout color="grey700" />
                <LinkContent>
                  <LinkText>{t('Sidebar.logout')}</LinkText>
                </LinkContent>
              </Link>
            </SidebarItem>
          </DropOut>
        </SidebarItem>
      </NavList>
    </Container>
  );
}
