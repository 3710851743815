import React from 'react';
import { useParams, useNavigate, generatePath } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { routes } from '@my-bb/common';
import { Tabs, Tab, GoBack } from '@my-bb/common-components';
import { Container, Section } from './styled';
import { ProfileSettings } from '../ProfileSettings';
import { PasswordSettings } from '../PasswordSettings';

const tabs = {
  profile: 'profile',
  password: 'password',
};

export function SettingsPage() {
  const { page } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  if (!tabs[page]) navigate(routes.NOT_FOUND);

  const selectTab = (id) => {
    navigate(generatePath(routes.SETTINGS, { page: id }));
  };

  return (
    <Container>
      <GoBack
        title={t(`main:SettingsTabs.title`)}
        subtitle={t('main:SettingsTabs.back')}
        onSubtitleClick={() => navigate(-1)}
      />
      <Section>
        <Tabs activeTab={page} onSelect={selectTab}>
          <Tab id={tabs.profile} label={t('main:SettingsTabs.profile')}>
            <Section>
              <ProfileSettings />
            </Section>
          </Tab>
          <Tab id={tabs.password} label={t('main:SettingsTabs.password')}>
            <Section>
              <PasswordSettings />
            </Section>
          </Tab>
        </Tabs>
      </Section>
    </Container>
  );
}
