import styled from 'styled-components';
import { PasswordIndicator, Field } from '@my-bb/common-components';

export const Indicator = styled(PasswordIndicator)`
  margin-top: 1rem;

  li {
    font-size: 0.875rem;
  }
`;

export const TwoFactorSection = styled(Field)`
  margin-top: 2.5rem;
`;

export const dividerStyle = {
  margin: 0,
};
