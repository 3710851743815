import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Heading, Text } from '@betty-blocks/design';
import { Skeleton } from '@my-bb/common-components';

import PropTypes from 'prop-types';

export function Welcome({ userData, isLoading }) {
  const { t } = useTranslation('main');
  return (
    <Box margin={{ bottom: '3.5rem' }}>
      <Skeleton
        margin=".5rem 0 1.1rem"
        isLoading={isLoading}
        height=".75rem"
        width="13rem"
      >
        <Heading margin={{ bottom: 'xsmall', top: '0' }} size="xlarge">
          {userData && `${t('Dashboard.hi')} ${userData.name}`}
        </Heading>
      </Skeleton>
      <Skeleton
        margin=".5rem 0"
        isLoading={isLoading}
        height=".5rem"
        width="24rem"
      >
        <Text color="grey700">{t('Dashboard.welcome')}</Text>
      </Skeleton>
    </Box>
  );
}

Welcome.propTypes = {
  userData: PropTypes.shape({
    name: PropTypes.string,
    avatar: PropTypes.string,
    email: PropTypes.string,
  }),
  isLoading: PropTypes.bool.isRequired,
};

Welcome.defaultProps = {
  userData: undefined,
};
