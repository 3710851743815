export const extractAppIdentifier = (
  redirectUrl: string,
): string | undefined => {
  return redirectUrl.includes('betty.services/')
    ? redirectUrl.split('/app/')[1]
    : (!redirectUrl.includes('/organizations/') &&
        redirectUrl.substring(
          redirectUrl.indexOf('https://') + 8,
          redirectUrl.lastIndexOf('.bettyblocks'),
        )) ||
        undefined;
};
export const extractOrgId = (redirectUrl: string): string => {
  const appIdentifierIndexInString = 1;
  return redirectUrl.split('/organizations/')[appIdentifierIndexInString];
};
export const checkBrokenUrl = (redirectUrl: string): boolean => {
  return (
    (extractAppIdentifier(redirectUrl) === undefined ||
      !extractAppIdentifier(redirectUrl)) &&
    extractOrgId(redirectUrl) === undefined
  );
};
