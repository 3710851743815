import React from 'react';
import PropTypes from 'prop-types';
import { Box, Heading, Text, Image } from '@betty-blocks/design';
import styled from 'styled-components';
import { FlatCard, ImageOverlay } from '../styled';
import playButton from '../../../assets/graphics/playButton.svg';

const StyledBox = styled(Box)`
  position: relative;
`;

export function VideoCard({ title, subTitle, thumbnail, handleClick }) {
  return (
    <FlatCard onClick={() => handleClick()}>
      <Box direction="row" height="100%" gap="medium">
        <StyledBox basis="2/5" height="100%">
          <Image src={thumbnail} fit="cover" height="100%" />
          <ImageOverlay>
            <Image alt={title} src={playButton} />
          </ImageOverlay>
        </StyledBox>
        <Box basis="3/5" justify="center">
          <Heading margin={{ top: '0' }}>{title}</Heading>
          <Text color="grey700">{subTitle}</Text>
        </Box>
      </Box>
    </FlatCard>
  );
}

VideoCard.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  thumbnail: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
};
