import styled from 'styled-components';

import { Box, Image } from '@betty-blocks/design';

export const MOBILE = 480;

export const Cards = styled.ul`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(3, minmax(auto, 1fr));
  @media (max-width: ${MOBILE}px) {
    grid-template-columns: repeat(auto-fill, minmax(12rem, 1fr));
  }
  margin: 0;
  padding: 0;
  color: inherit;
  text-decoration: none;
  list-style: none;
`;

export const HeaderImage = styled(Image)`
  object-position: left;
`;

export const HeaderLogo = styled(Image)`
  max-width: 12.5rem;
`;

export const HeaderLeftContainer = styled.div`
  max-width: 25rem;
`;

export const CardButtons = styled.div`
  position: absolute;
  z-index: 1;
  padding: 1rem 1rem 1.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  box-sizing: border-box;
  opacity: 0;
  transition: opacity 300ms linear;

  &::before {
    content: '';
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(52, 73, 94, 0.75);
  }

  & > button {
    position: relative;
  }
  & > button:nth-of-type(2) {
    margin-left: 1rem;
  }
`;

export const CardImageWrapper = styled.div<{
  backgroundImage: string;
  color?: string | null;
}>`
  position: relative;
  width: 100%;
  height: 16.875rem;
  overflow: hidden;
  border: 0.0625rem solid ${({ theme }): string => theme.colors.neutralGrey100};
  background-image: url('${({ backgroundImage }): string => backgroundImage}');
  background-size: cover;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: ${({ color }): string => color || 'rgba(0, 0, 0, 1)'};
    mix-blend-mode: color;
    opacity: 1;
  }
`;

export const CardImage = styled(Box)<{ backgroundImage: string }>`
  position: relative;
  z-index: 1;
  height: 16.875rem;
  background-image: url('${({ backgroundImage }): string => backgroundImage}');
  background-position: center;
  background-size: cover;
  border-bottom: ${({ theme }): string =>
    `0.0625rem solid ${theme.global.colors.grey100}`};
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.2));
  transform: scale(0.9);
  transform-origin: bottom center;
  transition: transform 200ms ease-out;
`;

export const CardContent = styled.div`
  color: ${({ theme }): string => theme.global.colors.grey700};
`;

export const CardTitle = styled.h3`
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
  color: ${({ theme }): string => theme.global.colors.grey900};
`;

export const CardIndustryAndDepartment = styled.div`
  font-size: 0.75rem;
  line-height: 1rem;
  margin-bottom: 0.5rem;
`;

export const CardIndustry = styled.span`
  display: inline-flex;
  margin-right: 1rem;

  & svg {
    margin-right: 0.25rem;
  }
`;

export const CardDepartment = styled.span`
  display: inline-flex;

  & svg {
    margin-right: 0.25rem;
  }
`;

export const CardDescription = styled.div`
  display: -webkit-box;
  width: 100%;
  margin-top: 0.25rem;
  overflow: hidden;
  font-size: 0.875rem;
  line-height: 1.25rem;
  white-space: normal;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow-wrap: break-word;
`;

export const CardFeatures = styled.div`
  font-size: 0.875rem;
  line-height: 1.25rem;
  margin-top: 0.5rem;
`;

export const CardFeature = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 0.25rem;
  & svg {
    fill: ${({ theme }): string => theme.global.colors.green700};
  }
`;

export const CardWrapper = styled.li<{
  isLoading: boolean;
  disabled: boolean;
}>`
  position: relative;
  display: flex;
  padding: 1rem;
  flex: 1;
  flex-direction: column;
  box-sizing: border-box;
  margin-top: 1.5625rem;
  border-radius: 0.25rem;
  cursor: ${({ isLoading }): string => (isLoading ? 'default' : 'pointer')};
  opacity: ${({ disabled }): number => (disabled ? 0.5 : 1)};
  transition:
    transform 0.2s ease 0s,
    box-shadow 0.2s ease 0s;
  pointer-events: ${({ disabled }): string => (disabled ? 'none' : 'all')};
  min-width: 0;
  &:hover {
    box-shadow:
      rgba(0, 0, 0, 0.2) 0 0.25rem 0.875rem 0,
      rgba(0, 0, 0, 0.2) 0 0 0.0625rem 0;

    ${CardButtons} {
      opacity: 1;
    }

    ${CardImage} {
      transform: scale(1.1);
    }
  }
  &:focus {
    outline: none;
  }
`;

export const HeaderContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  height: 100%;
  padding: 1.25rem 2rem 1rem 1rem;
  box-sizing: border-box;
  width: 40%;
  & > h1 {
    margin: 0;
  }
`;

export const HeaderLogoWrapper = styled.div`
  max-width: 18rem;
  margin: 3rem 0 2rem;

  & > img {
    width: 100%;
    object-fit: contain;
  }
`;

export const HeaderImageWrapper = styled.div`
  padding-left: 0;
  height: 100%;
  width: 60%;
  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const GalleryTitle = styled.h1`
  color: ${({ theme }): string => theme.colors.neutralGrey900};
  font-size: 2rem;
  font-weight: 700;
  line-height: 2.5rem;
  padding-bottom: 0.75rem;
  margin: 0;
`;

export const GalleryDescription = styled.p`
  margin: 0 0 1rem;
  font-size: 1rem;
  line-height: 1.5rem;
  color: ${({ theme }): string => theme.colors.neutralGrey700};
`;

export const SearchWrapper = styled.div`
  width: 18rem;
  margin-bottom: 1rem;
  visibility: hidden;

  & input {
    font-size: 0.875rem;
  }
`;

export const GalleryDetailTags = styled.div`
  display: flex;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  font-size: 0.75rem;
  line-height: 1rem;
  color: ${({ theme }): string => theme.colors.globalTeal700};
  margin-right: 0.5rem;
  background-color: ${({ theme }): string => theme.colors.globalTeal50};
  margin-bottom: 0.5rem;
`;

export const GalleryDetailTagsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-top: 0.25rem;
`;
