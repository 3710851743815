import styled from 'styled-components';
import { Text, Button, Heading } from '@betty-blocks/design';
import { PasswordInput, FormField, TextInput } from '@my-bb/common-components';

export const Main = styled.main<{ backgroundImage?: string }>`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-image: url('${({ backgroundImage }): string | undefined =>
    backgroundImage}');
  background-repeat: no-repeat;
  background-position: left;
  background-size: cover;
`;

export const InputWrapper = styled.div`
  margin-bottom: 1.5rem;
`;

export const BiggerInput = styled(TextInput)`
  height: 3.5rem;
  font-size: 1rem;
  display: inline-block;
  grid-template-columns: 2rem auto 2rem;
  padding: 0.75rem 0.875rem 0.75rem 1rem;
  width: 100%;
  font-weight: 400;
  &:focus {
    padding: 0.75rem 0.875rem 0.75rem 1rem;
  }
`;

export const PasswordBiggerInput = styled(PasswordInput)`
  height: 3.5rem;
  font-size: 1rem;
  display: inline-block;
  grid-template-columns: 2rem auto 2rem;
  padding: 0.75rem 0.875rem 0.75rem 1rem;
  width: 100%;
  font-weight: 400;
  &:focus {
    padding: 0.75rem 0.875rem 0.75rem 1rem;
  }
`;

export const BiggerFormField = styled(FormField)`
  margin-bottom: 0;
  > label {
    font-size: 0.875rem;
    pointer-events: none;
    font-weight: bold;
  }
`;

export const ConfirmButton = styled(Button)`
  height: 3.5rem;
  font-weight: bold;
  flex: none;
  font-size: 1rem;
  width: 100%;
  margin-top: 1rem;
`;

export const Title = styled(Heading)`
  margin-top: 3rem;
  margin-bottom: 0;
  font-size: 2.5rem;
  line-height: 2rem;
`;

export const SubTitle = styled(Text)`
  padding-top: 1rem;
  padding-bottom: 2.5rem;
  font-size: 1rem;
  line-height: 1.25rem;
  display: block;
`;
