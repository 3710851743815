import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  useCheckResetPasswordTokenQuery,
  type ErrorPageType,
} from '@my-bb/common';
import { useParams } from 'react-router-dom';
import { ErrorPage, FullPageLoader } from '@my-bb/common-components';
import { errorUtils } from '@my-bb/common-utils';
import { ResetPasswordForm } from '../ResetPasswordForm';

export function ResetPassword(): JSX.Element {
  const { t } = useTranslation('main');
  const { token = '' } = useParams();
  const { loading, data: { checkResetPasswordToken } = {} } =
    useCheckResetPasswordTokenQuery({
      variables: { token },
      skip: !token,
    });

  const { type } = errorUtils.extractError(checkResetPasswordToken);

  if (type || !token) {
    return (
      <ErrorPage
        isFullPage
        type={type as ErrorPageType}
        subject={t('ResetPassword.request.info')}
      />
    );
  }
  if (loading) {
    return <FullPageLoader />;
  }

  const twoFactorEnabled =
    checkResetPasswordToken?.__typename === 'User'
      ? checkResetPasswordToken.twoFactorEnabled
      : false;

  return (
    <ResetPasswordForm token={token} twoFactorEnabled={twoFactorEnabled} />
  );
}
