import React from 'react';
import { Box, Heading, Text } from '@betty-blocks/design';
import { useTranslation } from 'react-i18next';
import { useMixpanel } from '@my-bb/common-components';
import { VideoSection } from './Video/VideoSection';
import {
  Cards,
  Card,
  StyledNewTabIcon,
  Wrapper,
  WrapperSection,
  IconBox,
  StyledText,
} from './styled';
import { learnings } from './constant';
import { Icon } from './Icon';

export function Learn() {
  const { t } = useTranslation('main');
  const { trackEvent } = useMixpanel();

  const clickCardHandler = (object) => {
    trackEvent(`Open learning resource: ${object.key}`);
    window.open(object.url, '_blank');
  };

  return (
    <Box margin={{ top: '3.5rem' }}>
      <Heading margin={{ bottom: 'xsmall' }} size="large">
        {t('Dashboard.learn')}
      </Heading>
      <Text margin={{ bottom: 'large' }} color="grey700">
        {t('Dashboard.learnSubtitle')}
      </Text>
      <WrapperSection>
        <Wrapper>
          <VideoSection />
        </Wrapper>
      </WrapperSection>

      <Box>
        <Cards>
          {learnings.map((object) => (
            <Card
              pad="1.5rem"
              key={object.key}
              onClick={() => clickCardHandler(object)}
            >
              <StyledNewTabIcon color="grey700" />
              <Box align="center" justify="center">
                <IconBox>
                  <Icon size="2rem" color="turquoise500" type={object.key} />
                </IconBox>
                <Heading
                  margin={{ bottom: 'xsmall', top: 'xlarge' }}
                  size="medium"
                >
                  {t(`Dashboard.${object.key}`)}
                </Heading>
                <StyledText textAlign="center" color="grey700">
                  {t(`Dashboard.${object.key}Info`)}
                </StyledText>
              </Box>
            </Card>
          ))}
        </Cards>
      </Box>
    </Box>
  );
}
