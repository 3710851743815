import React from 'react';

import type { GallerListItem } from './types';
import Card from './Card';
import { Cards } from './styled';

interface Props {
  items: (GallerListItem | null)[];
}

export function List({ items }: Props): JSX.Element {
  return (
    <Cards>
      <>
        {items.map((item: GallerListItem | null): JSX.Element | null => {
          if (item) {
            return <Card key={item.id} item={item} />;
          }
          return null;
        })}
      </>
    </Cards>
  );
}
