import styled from 'styled-components';
import { Box } from '@betty-blocks/design';

export const Page = styled.div<{ flex?: boolean }>`
  display: ${({ flex }): string => (flex ? 'flex' : 'block')};
  flex-direction: ${({ flex }): string | undefined =>
    flex ? 'column' : undefined};
  min-width: 60rem;
  min-height: 100vh;
`;

export const HeaderWrapper = styled.div<{
  isScrolled?: boolean;
}>`
  position: ${({ isScrolled }): string | undefined =>
    isScrolled ? 'fixed' : undefined};
  top: 0;
  right: 0;
  left: 0;
  z-index: 11;
  background: ${({ theme }): string => theme.global.colors.white};
  box-shadow: 0 0.125rem 0.125rem -0.125rem rgba(0, 0, 0, ${({ isScrolled }): number => (isScrolled ? 0.2 : 0)});
  border-top: ${({ theme }): string =>
    `0.25rem solid ${theme.global.colors.turquoise500}`};
  transition: box-shadow 0.2s ease;
`;

export const PageHeader = styled.div<{ hasImage: boolean }>`
  height: ${({ hasImage }): string | undefined =>
    hasImage ? '35.5rem' : undefined};

  ${HeaderWrapper} {
    height: ${({ hasImage }): string | undefined =>
      hasImage ? '35.25rem' : undefined};
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  box-sizing: border-box;
  max-width: 100%;
  margin-right: 1.5rem;
  min-width: 0px;
  min-height: 0px;
  flex-direction: column;
  flex: 1 1 29.5rem;
  box-shadow: none;

  & + div {
    flex: 1 1 42rem;
    flex-grow: 0;
  }
`;

export const ImageWrapper = styled(Box)`
  border-radius: 0.25rem;
  border: ${({ theme }): string =>
    `0.0625rem solid ${theme.colors.neutralGrey100}`};
  padding: 0;
  margin: 5rem 0;
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  max-width: 90rem;
  height: 100%;
  box-sizing: border-box;
  margin: 0 auto;
`;

export const GalleryDivider = styled.div`
  border-top: ${({ theme }): string =>
    `0.0625rem solid ${theme.global.colors.grey300}`};
  max-width: 100%;
  margin: 0 auto;
`;

export const GalleryListWrapper = styled.div`
  padding-top: 2.5rem;
  padding-bottom: 1rem;
  max-width: 90rem;
  margin: 0 auto;
`;

export const GalleryDetailContainer = styled.div`
  border-top: ${({ theme }): string =>
    `0.25rem solid ${theme.global.colors.turquoise500}`};
  margin: 0 auto;
`;

export const GalleryDetailHeader = styled.div`
  height: 36.125rem;
  border-bottom: ${({ theme }): string =>
    `0.0625rem solid ${theme.global.colors.grey100}`};
`;

export const GalleryDetailHeaderContent = styled.div`
  max-width: 90rem;
  height: 100%;
  margin: 0 auto;
`;

export const GalleryDetailHeaderTitle = styled.h1`
  font-size: 2rem;
  font-weight: 700;
  line-height: 2.5rem;
  margin-bottom: 0.5rem;
`;

export const GalleryDetailIndustryAndDepartment = styled.div`
  font-size: 0.75rem;
  line-height: 1rem;
  color: ${({ theme }): string => theme.global.colors.grey700};
`;

export const GalleryDetailIndustry = styled.span`
  display: inline-flex;
  margin-right: 1rem;

  & svg {
    margin-right: 0.25rem;
  }
`;

export const GalleryDetailDepartment = styled.span`
  display: inline-flex;

  & svg {
    margin-right: 0.25rem;
  }
`;

export const GalleryDetailHeaderDescription = styled.p`
  font-size: 1rem;
  margin-top: 0.5rem;
  line-height: 1.5rem;
  color: ${({ theme }): string => theme.global.colors.grey700};
`;

export const GalleryDetailContent = styled.div`
  max-width: 90rem;
  margin: 0 auto;
  padding: 2rem 5.25rem;
  display: flex;
  justify-content: space-between;
`;

export const GalleryDetailAside = styled.aside`
  width: 21.75rem;
`;

export const GalleryDetailTags = styled.div`
  display: flex;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  font-size: 0.825rem;
  line-height: 1rem;
  color: ${({ theme }): string => theme.colors.globalTeal700};
  margin-right: 0.5rem;
  background-color: ${({ theme }): string => theme.colors.globalTeal50};
  margin-bottom: 0.5rem;
`;

export const GalleryDetailTagsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-top: 1rem;
`;

export const GalleryDetailAsideBox = styled.div`
  border: ${({ theme }): string =>
    `0.0625rem solid ${theme.global.colors.grey100}`};
  padding: 1.5rem;
  margin-bottom: 1.5rem;
  color: ${({ theme }): string => theme.global.colors.grey700};
`;

export const GalleryDetailOrganization = styled.div`
  text-align: center;
`;

export const GalleryDetailOrganizationLogo = styled.div`
  width: 6rem;
  height: 6rem;
  margin: 0 auto 1rem;
  border: ${({ theme }): string =>
    `0.0625rem solid ${theme.global.colors.grey100}`};
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;

  & img {
    max-width: 100%;
  }
`;

export const GalleryDetailOrganizationHeader = styled.h3`
  font-size: 1.25rem;
  line-height: 1.75rem;
  color: ${({ theme }): string => theme.global.colors.grey900};
  margin-top: 0;
  margin-bottom: 0;

  & a {
    color: ${({ theme }): string => theme.global.colors.grey900};
  }
`;

export const GalleryDetailOrganizationText = styled.div`
  font-size: 1rem;
  line-height: 1.5rem;

  & a {
    color: ${({ theme }): string => theme.global.colors.grey700};
  }
`;

export const GalleryDetailFeatures = styled.div`
  font-size: 1rem;
  line-height: 1.25rem;
`;

export const GalleryDetailFeatureHeader = styled.h3`
  font-size: 1.25rem;
  line-height: 1.75rem;
  color: ${({ theme }): string => theme.global.colors.grey900};
  margin-top: 0;
  margin-bottom: 0.75rem;
`;

export const GalleryDetailFeature = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 0.25rem;
  & svg {
    fill: ${({ theme }): string => theme.global.colors.green700};
    margin-right: 0.25rem;
  }
`;

export const ButtonWrapper = styled.div`
  margin-top: 0.5rem;
  margin-bottom: 1rem;

  & button {
    margin-right: 0.5rem;
  }
`;

export const VideoWrapper = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding-left: 7.5rem;
  position: relative;
`;

export const VideoTitle = styled.h3`
  font-size: 0.875rem;
  line-height: 1rem;
  margin-bottom: 0.25rem;
  margin-top: 1.25rem;
`;

export const VideoBody = styled.div`
  font-size: 0.75rem;
  line-height: 0.875rem;
  color: ${({ theme }): string => theme.global.colors.grey700};
`;

export const VideoPlay = styled.button`
  position: absolute;
  width: 6.25rem;
  height: 3.5rem;
  top: 0.5rem;
  left: 0;
  border-radius: 0.25rem;
  background-color: ${({ theme }): string => theme.global.colors.grey900};
  border: none;
  cursor: pointer;
`;

export const VideoPlayIconWrapper = styled.span`
  position: absolute;
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const InterestBox = styled.div`
  border-radius: 0.25rem;
  text-align: center;
  padding: 1.5rem;
  box-shadow: 0rem 0.0625rem 0.25rem 0rem rgba(102, 109, 133, 0.25);
`;

export const InterestBodyText = styled.div`
  font-size: 1rem;
  line-height: 1.5rem;
  margin-bottom: 0.75rem;
  color: ${({ theme }): string => theme.global.colors.grey700};
`;

export const InterestFooterText = styled.div`
  font-size: 0.75rem;
  line-height: 1rem;
  margin-top: 0.75rem;
  color: ${({ theme }): string => theme.global.colors.grey700};
`;
