import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Content,
  ContentSection,
  BettyBlocksLogo,
  FooterSection,
  FooterLogo,
  FooterText,
} from './styled';

interface Props {
  children: JSX.Element | JSX.Element[];
}

export function FormSection({ children }: Props): JSX.Element {
  const { t } = useTranslation('main');

  return (
    <Content>
      <ContentSection>
        <BettyBlocksLogo />
        {children}
      </ContentSection>
      <FooterSection>
        <FooterLogo />
        <FooterText color="grey700">
          {t('MainPage.footer', { year: new Date().getFullYear() })}
        </FooterText>
      </FooterSection>
    </Content>
  );
}
