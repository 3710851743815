import React from 'react';

import { FormSection } from '../FormSection';
import { Main } from './styled';

interface Props {
  children: JSX.Element | JSX.Element[];
  backgroundImage?: string;
}

export function MainPage({
  children,
  backgroundImage = 'https://assets.bettyblocks.com/8e60b343ffa246e28a5a046c7c7e6140_assets/files/my-bb-bg.jpg',
}: Props): JSX.Element {
  return (
    <Main backgroundImage={backgroundImage}>
      <FormSection>{children}</FormSection>
    </Main>
  );
}
