import React, { useEffect } from 'react';
import { useMeQuery } from '@my-bb/common';
import { Section, useMixpanel, HeaderRow } from '@my-bb/common-components';

import { Welcome } from '../../components/DashboardPage/Welcome';
import { Learn } from '../../components/DashboardPage/Learn';
import { StartBuilding } from '../../components/DashboardPage/StartBuilding';

export function DashboardPage(): JSX.Element {
  const { trackEvent } = useMixpanel();
  useEffect(() => {
    if (trackEvent) trackEvent('Open mybb dashboard');
  }, [trackEvent]);

  const { data, loading } = useMeQuery();

  return (
    <div>
      <Section>
        <HeaderRow />
        <Welcome userData={data && data.me} isLoading={loading} />
        <StartBuilding
          canCreateApplication={Boolean(
            data?.me?.permissions?.canCreateApplication,
          )}
        />
        <Learn />
      </Section>
    </div>
  );
}
