import styled from 'styled-components';
import { Text } from '@betty-blocks/design';
import { FormField } from '@my-bb/common-components';

export const StyledLabel = styled.label`
  color: #666d85;
  font-weight: bold;
  font-size: 0.875rem;
  text-transform: uppercase;
`;

export const StyledInfoText = styled(Text)`
  display: block;
`;

export const BiggerText = styled(Text)`
  font-size: 1rem;
`;

export const StyledLink = styled(Text)`
  font-weight: bold;
  font-size: 1rem;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

export const StyledFormField = styled(FormField)`
  margin: 0;
`;

export const StyledText = styled.p`
  margin: 0;
  color: #262a3a;
  font-size: 1rem;
  line-height: 1.4rem;
`;
