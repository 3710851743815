import {
  EMPTY_SEARCH_ERROR,
  INVALID_INPUT_ERROR,
  NO_APPS_ERROR,
  NO_APPS_WITH_STATUS_ERROR,
  NO_BLOCKS_ERROR,
  NO_BLOCKS_FILTER,
  NO_CHANGES_ERROR,
  NO_ORGS_ERROR,
  NO_ORGS_FILTER,
  NO_PROSPECTS_ERROR,
  NO_BUILDERS_ERROR,
  NO_USERS_ERROR,
  NO_TEMPLATES_ERROR,
  NO_TEMPLATES_FILTER,
  NO_AUDIT_LOGS_ERROR,
  NO_AUDIT_LOGS_FILTER,
  NO_API_KEYS_ERROR,
  NO_API_KEYS_SEARCH,
  NO_GALLERY_ERROR,
  NO_GALLERY_ITEMS_ERROR,
  NO_MERGES_ERROR,
  NO_MERGES_FILTER,
  NOT_ACCEPTED,
  NOT_FOUND_ERROR,
  UNAUTHORIZED_ERROR,
  UNEXPECTED_ERROR,
  UNPROCESSABLE_ENTITY_ERROR,
  NO_APPLICATION_MEMBERSHIPS_ERROR,
  NO_ORGANIZATION_MEMBERSHIPS_ERROR,
  NO_CUSTOMER_MEMBERS_ERROR,
} from '../../constants';

// These keys are mapped with backend invalidInput keys and can contain context (is field key)
export const NOT_FOUND = 'The application does not exist.';
export const FIELD_UNIQUE =
  'This $t(common:Fields.{{context}}) is already taken.';
export const FIELD_REQUIRED = 'This field is required';
export const NOT_EQUAL = 'Passwords are not equal';
export const FIELD_MIN_LENGTH =
  'This field should be at least {{threshold}} characters.';
export const FIELD_MAX_LENGTH =
  'This field should be less than {{threshold}} characters.';
export const FIELD_WRONG_FORMAT = '$t(common:Fields.{{context}}) is invalid.';
export const FIELD_WRONG_VALUE = '$t(common:Fields.{{context}}) is invalid.';
export const FIELD_WRONG_FORMAT_PASSWORD =
  'This field does not meet the requirements, please try again.';
export const FIELD_BREACHED_PASSWORD =
  'Password is a known breached value, it is not secure, please try again.';
export const FILE_WRONG_FORMAT = 'This file is not supported.';
export const FILE_TOO_BIG =
  'The uploaded image exceeds the maximum upload size of {{max}}.';
export const FIELD_NOT_ALLOWED = FIELD_UNIQUE;
export const DUPLICATE_EMAIL = 'Duplicate email found.';
export const ALREADY_TAKEN = 'Duplicate item found in {{value}}.';
export const INVALID = 'Invalid value';

export const ErrorPage = {
  [NO_CHANGES_ERROR]: {
    title: 'There are currently no changes.',
    title_updates: 'There are currently no updates.',
    title_versions: 'There is no rollback available.',
    message: 'You can refresh this page when you have made new changes.',
    message_versions:
      'You can do one rollback of the most recent merged changes.',
  },
  [NOT_FOUND_ERROR]: {
    title: 'Your page has gone missing.',
    message: `We can't seem to find the page you're looking for.`,
  },
  [UNAUTHORIZED_ERROR]: {
    title: 'This content is locked.',
    message: `It seems like you don't have permission to access this content.`,
  },
  [UNEXPECTED_ERROR]: {
    title: 'Oops, something went wrong!',
    message: 'Please try again later or try and reload the page.',
  },
  [UNPROCESSABLE_ENTITY_ERROR]: {
    title: 'We are currently unable to process this request.',
  },
  [EMPTY_SEARCH_ERROR]: {
    title: 'No matching search results.',
    message: 'Try again using another search term.',
  },
  [NO_APPS_ERROR]: {
    title: 'You don’t have any applications yet.',
  },
  [NO_APPS_WITH_STATUS_ERROR]: {
    title: 'You don’t have any applications with selected status yet.',
  },
  [NO_TEMPLATES_ERROR]: {
    title: 'You don’t have any templates yet.',
  },
  [NO_TEMPLATES_FILTER]: {
    title: 'No matching template found',
    message: 'Try again using another filter',
  },
  [NO_AUDIT_LOGS_ERROR]: {
    title: 'There are no audit logs yet.',
  },
  [NO_AUDIT_LOGS_FILTER]: {
    title: 'No matching audit logs found',
    message: 'Try again using another filter',
  },
  [NO_API_KEYS_ERROR]: {
    title: 'You don’t have any API keys yet.',
  },
  [NO_API_KEYS_SEARCH]: {
    title: 'No matching API key found',
    message: 'Try again using another search term',
  },
  [NO_ORGS_ERROR]: {
    title: 'You don’t have any organizations yet.',
  },
  [NO_ORGS_FILTER]: {
    title: 'No matching organization found',
    message: 'Try again using another filter',
  },
  [NO_BLOCKS_ERROR]: {
    title: `It's empty in here`,
  },
  [NO_BLOCKS_FILTER]: {
    title: 'No matching block found',
    message: 'Try again using another filter',
  },
  [NO_PROSPECTS_ERROR]: {
    title: 'There are no pending invitations',
  },
  [NO_BUILDERS_ERROR]: {
    title: 'You don’t have any builders yet.',
    message: 'Invite builders to your application',
  },
  [NO_USERS_ERROR]: {
    title: 'You don’t have any users yet.',
    message: 'Invite users to your application',
  },
  [NO_GALLERY_ERROR]: {
    title: 'You don’t have a gallery yet.',
    message: 'Create an app gallery and inspire others.',
  },
  [NO_GALLERY_ITEMS_ERROR]: {
    title: 'You don’t have any gallery items yet.',
    message: 'Add gallery items and inspire others.',
  },
  [NO_MERGES_ERROR]: {
    title: `There aren't any merges yet`,
  },
  [NO_MERGES_FILTER]: {
    title: 'No matches found',
    message: 'Try again using another filter',
  },
  [NO_APPLICATION_MEMBERSHIPS_ERROR]: {
    title: 'You don’t have any application memberships yet.',
  },
  [NO_ORGANIZATION_MEMBERSHIPS_ERROR]: {
    title: 'You don’t have any organization memberships yet.',
  },
  [NO_CUSTOMER_MEMBERS_ERROR]: {
    title: 'You don’t have any customer members yet.',
    message: 'Invite customer managers to your customer',
  },
};

export const ErrorToast = {
  [UNAUTHORIZED_ERROR]: 'Oops, You do not have permission to do that',
  [UNEXPECTED_ERROR]:
    'Oops, something went wrong! Please try again later or try and reload the page.',
  [NOT_FOUND_ERROR]: 'We cannot find what you are looking for.',
  [INVALID_INPUT_ERROR]: 'This user already exists.',
  [UNPROCESSABLE_ENTITY_ERROR]:
    'We are currently unable to process this request.',
  [NOT_ACCEPTED]: `You can't demote a master template which has sub applications connected to it`,
  [`${UNPROCESSABLE_ENTITY_ERROR}_invalid_app_state`]:
    'You are currently unable to {{subject}} because there is already a similar task in progress.',
  failed: 'Oops, something went wrong!',
};

export const General = {
  email: 'You have entered an invalid e-mail address.',
  emailDuplicate: 'This email address has already been added.',
  fetchingRolesFailed: 'Fetching roles failed.',
};
