import React from 'react';

import type { Gallery } from '@my-bb/common';
import { Search } from '@my-bb/common-components';

import {
  GalleryDescription,
  GalleryTitle,
  HeaderContent,
  HeaderImage,
  HeaderImageWrapper,
  HeaderLeftContainer,
  HeaderLogo,
  HeaderLogoWrapper,
  SearchWrapper,
} from './styled';

interface Props {
  gallery: Omit<Gallery, 'id' | 'galleryItems'>;
  onSearchChange: (arg0: string) => void;
  search?: string;
}

export function Header({
  gallery,
  onSearchChange,
  search,
}: Props): JSX.Element | null {
  return (
    <>
      <HeaderContent>
        <HeaderLeftContainer>
          <HeaderLogoWrapper>
            {gallery.logo && (
              <HeaderLogo alt="App gallery" src={gallery.logo} />
            )}
          </HeaderLogoWrapper>
          <GalleryTitle>{gallery.title}</GalleryTitle>
          <GalleryDescription>{gallery.description}</GalleryDescription>
          <SearchWrapper>
            <Search defaultValue={search} onChange={onSearchChange} />
          </SearchWrapper>
        </HeaderLeftContainer>
      </HeaderContent>
      <HeaderImageWrapper>
        {gallery.image && <HeaderImage alt="App gallery" src={gallery.image} />}
      </HeaderImageWrapper>
    </>
  );
}
