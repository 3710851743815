import styled from 'styled-components';
import { Box } from '@betty-blocks/design';
import { DESKTOP_SMALL } from '../../constant';

export const HeaderBox = styled(Box)`
  @media (max-width: ${DESKTOP_SMALL}px) {
    flex-direction: column;
  }
`;

export const ButtonContainer = styled(Box)`
  @media (max-width: ${DESKTOP_SMALL}px) {
    align-self: flex-end;
    margin: 1rem 0;
  }
`;
