import React from 'react';
import { Heading } from '@betty-blocks/design';
import { Li, Tooltip, TooltipContent, Arrow } from './styled';

interface Props {
  children: JSX.Element | JSX.Element[];
  className?: string;
  onClick?: () => void;
  tooltipText?: string;
  isActive?: boolean;
}

export function SidebarItem({
  children,
  className,
  onClick,
  tooltipText,
  isActive,
}: Props): JSX.Element {
  return (
    <Li className={className} onClick={onClick}>
      {children}
      {!!tooltipText && !isActive && (
        <Tooltip className="custom-tooltip">
          <Arrow />
          <TooltipContent>
            <Heading level={3} color="white" margin="none">
              {tooltipText}
            </Heading>
          </TooltipContent>
        </Tooltip>
      )}
    </Li>
  );
}
