import React, { Fragment, useState } from 'react';
import { Box } from '@betty-blocks/design';
import {
  TemplateTile,
  CreateMainAppModal,
  TemplateCards,
} from '@my-bb/common-components';
import { useNavigate, generatePath } from 'react-router-dom';
import { useDashboardTemplatesQuery, Template, routes } from '@my-bb/common';
import type { ScratchTemplate } from '@my-bb/common-components';
import fromScratchPlaceholder from '../../../assets/graphics/templateFromScratchPlaceholder.svg';
import { Header } from './Header';

const take = (templates: Template[], type: string): Template[] => {
  return templates.filter(
    ({ type: templateType }): boolean => templateType === type,
  );
};

const fromScratchTemplate: ScratchTemplate = {
  description:
    'Create an empty application to start building from scratch. Not recommended for starters.',
  name: 'No template',
  type: undefined,
  templateImages: [
    { __typename: 'TemplateImageQuery', image: fromScratchPlaceholder },
  ],
};

const toDashboard = (allTemplates: Template[]): Template[] => {
  const templates = allTemplates.filter(
    (template) => template.showOnDashboard && template.type !== 'GUIDE',
  );
  const sortedTemplates = templates.sort((a, b) => {
    if (typeof a.orderIndex !== 'number' || typeof b.orderIndex !== 'number') {
      return 0;
    }
    return a.orderIndex - b.orderIndex;
  });
  const guides = take(sortedTemplates, 'guide');
  const normalTemplates = take(sortedTemplates, 'template');
  return guides.length > 0
    ? [...guides.slice(0, 1), ...normalTemplates.slice(0, 2)]
    : normalTemplates.slice(0, 3);
};

export function StartBuilding({
  canCreateApplication,
}: {
  canCreateApplication: boolean;
}): JSX.Element {
  const navigate = useNavigate();
  const { data, loading } = useDashboardTemplatesQuery();
  const templates =
    data && data.dashboardTemplates ? data.dashboardTemplates : [];
  const [selectedTemplate, setSelectedTemplate] = useState<
    Template | ScratchTemplate | null
  >(null);
  return (
    <Box>
      <Header />
      <TemplateCards data-testid="templates-list">
        {loading && (
          <>
            <TemplateTile isLoading={loading} />
            <TemplateTile isLoading={loading} />
            <TemplateTile isLoading={loading} />
          </>
        )}
        {!loading &&
          templates &&
          toDashboard(templates as Template[]).map(
            (template: Template): JSX.Element => (
              <Fragment key={template.id}>
                <TemplateTile
                  template={template}
                  setSelectedTemplate={setSelectedTemplate}
                  isLoading={loading}
                  canCreateApplication={canCreateApplication}
                />
              </Fragment>
            ),
          )}
        <TemplateTile
          template={fromScratchTemplate}
          setSelectedTemplate={setSelectedTemplate}
          isLoading={loading}
          canCreateApplication={canCreateApplication}
        />
      </TemplateCards>
      {selectedTemplate && (
        <CreateMainAppModal
          onDismiss={() => setSelectedTemplate(null)}
          afterSuccess={({ organization: { id: organizationId } }) => {
            navigate(
              generatePath(routes.ORGANIZATIONS + routes.ORGANIZATION_DETAILS, {
                id: organizationId.toString(),
              }),
            );
          }}
          template={selectedTemplate}
        />
      )}
    </Box>
  );
}
