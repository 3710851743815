import React, { StrictMode } from 'react';
import { Buffer } from 'buffer';
import { createRoot } from 'react-dom/client';
import '@betty-blocks/core-components/dist/tailwind.css';
import './index.css';
import './bb-markdown.css';
import { App } from './App';
import * as serviceWorker from './serviceWorker';
import { InitSentry } from './components/InitSentry';

window.Buffer = Buffer;

const container = document.getElementById('root');
if (container) {
  InitSentry();
  const root = createRoot(container);
  root.render(
    <StrictMode>
      <App />
    </StrictMode>,
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
