export const MOBILE = 480;
export const TABLET = 768;
export const DESKTOP_SMALL = 1024;
export const DESKTOP_MEDIUM = 1440;
export const DESKTOP_LARGE = 1920;
export const DESKTOP_XLARGE = 2560;
export const TOUR_IDENTIFIER = '08546d54-f47b-4914-b907-a62c59944851';

export const learnings = [
  {
    key: 'learning',
    url: 'https://learning.betty.app',
  },
  {
    key: 'documentation',
    url: 'https://docs.bettyblocks.com/',
  },
  {
    key: 'productChanges',
    url: 'https://blog.bettyblocks.com/product-changes',
  },
  {
    key: 'community',
    url: 'https://community.betty.app/',
  },
];
