import styled from 'styled-components';
import { NewTabIcon, Box, Text } from '@betty-blocks/design';
import { MOBILE } from './constant';

export const StyledNewTabIcon = styled(NewTabIcon)`
  position: absolute;
  top: 1rem;
  right: 1rem;
  opacity: 0;
  transition: opacity 0.2s linear;
`;

export const Cards = styled.ul`
  display: grid;
  grid-auto-rows: 1fr;
  grid-gap: 2.5rem;
  grid-template-columns: repeat(auto-fit, minmax(18rem, 1fr));
  @media (max-width: ${MOBILE}px) {
    grid-template-columns: repeat(auto-fill, minmax(12rem, 1fr));
  }
  margin: 0;
  padding: 0;
  list-style: none;
  & a {
    color: inherit;
    text-decoration: none;
  }
`;

export const Card = styled.li`
  padding: 1.5rem;
  text-align: left;
  list-style: none;
  border-radius: 0.25rem;
  box-shadow:
    rgba(0, 0, 0, 0.05) 0 -0.0625rem 0.0625rem 0,
    rgba(0, 0, 0, 0.2) 0 0.0625rem 0.125rem 0;
  transform: translateY(0);
  cursor: pointer;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  transition:
    transform 0.2s ease 0s,
    box-shadow 0.2s ease 0s;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'all')};
  &:hover {
    box-shadow:
      rgba(0, 0, 0, 0.2) 0 0.25rem 0.875rem 0,
      rgba(0, 0, 0, 0.2) 0 0 0.0625rem 0;
    transform: translateY(-0.25rem);
  }
  &:focus {
    outline: none;
  }
`;

export const FlatCard = styled(Card)`
  height: 10rem;
  padding: 1rem;
`;

export const ImageOverlay = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
`;

export const IframeContainer = styled.div`
  position: relative;
  padding: 56.25% 0 0 0;
  background: ${({ theme }) => theme.global.colors.grey100};
  margin-bottom: 2rem;
`;

export const Iframe = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const Wrapper = styled.li((props) => ({
  gridTemplateColumns: 'repeat(auto-fit, minmax(18rem, 1fr))',
  backgroundColor: `${props.backgroundColor}`,
  width: '50%',
  borderRadius: '0.25rem',
  padding: `${props.padding}`,
  display: 'flex',
  flexDirection: 'column',
}));

export const WrapperSection = styled.ul`
  display: grid;
  grid-auto-rows: 1fr;
  grid-gap: 2.5rem;
  grid-template-columns: repeat(auto-fit, minmax(18rem, 1fr));
  @media (max-width: ${MOBILE}px) {
    grid-template-columns: repeat(auto-fit, minmax(12rem, 1fr));
  }
  padding: 0;
  margin: 0;
  margin-bottom: 2.5rem;
  text-decoration: none;
  list-style: none;
`;

export const ButtonWrapper = styled.div`
  margin-top: auto;
`;

export const IconBox = styled(Box)`
  padding: 22px;
  text-align: center;
  vertical-align: middle;
  background: rgb(227, 248, 252);
  border-radius: 50%;
`;

export const StyledText = styled(Text)`
  min-height: 2.625rem;
`;
