import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { mutations, routes } from '@my-bb/common';
import { FullPageLoader } from '@my-bb/common-components';
import { InviteRegistrationForm } from '../InviteRegistrationForm';

const { DASHBOARD } = routes;

export function InviteRegistration(): JSX.Element {
  const { token } = useParams();
  const redirectToDashboard = `//${window.location.hostname}${DASHBOARD}`;

  const [validate, { loading }] = useMutation(mutations.validateInviteToken, {
    variables: {
      input: {
        token,
      },
    },
    onCompleted: (completeData) => {
      const { __typename: typename } = completeData.validateInviteToken;
      if (typename === 'InvalidInputError') {
        window.location.replace(redirectToDashboard);
      }
    },
  });

  useEffect(() => {
    validate();
  }, [validate]);

  if (loading) return <FullPageLoader />;

  return <InviteRegistrationForm />;
}
